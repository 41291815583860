<template>
  <div id="contact-form">
    <h2>{{ $t('footer.contactForm.title') }}</h2>
    <form @submit.prevent="submitForm">
      <input type="text" class="text-input" :placeholder="$t('footer.contactForm.name')" />
      <input type="text" class="text-input" :placeholder="$t('footer.contactForm.email')" />
      <input type="text" class="text-input" :placeholder="$t('footer.contactForm.phone')" />
      <input type="text" class="text-input" :placeholder="$t('footer.contactForm.message')" />
      <button class="btn">{{ $t('footer.contactForm.button') }}</button>
    </form>
  </div>
</template>

<script>
import { postContactForm } from '@/api/contact'

export default {
  name: 'ContactForm',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  },
  methods: {
    submitForm() {
      const data = {
        to: process.env.VUE_APP_TO_MAILER,
        from: process.env.VUE_APP_FROM_MAILER,
        subject: 'Nuevo contacto de Wardenclyffe',
        text: 'Un usuario quiere contactarse desde la página de Wardenclyffe:\n\n' +
              `Nombre: ${this.name}\n` +
              `Email: ${this.email}\n` +
              `Teléfono: ${this.phone}\n` +
              `Mensaje: ${this.message}`
      }

      postContactForm(data).then(response => {
        console.log(response)
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#contact-form {
  margin: 1rem;
  text-align: center;
  width: calc(100% / 3);
}

.text-input {
  background: none;
  border: 0;
  border-bottom: 1px solid $black;
  font-size: 1.25rem;
  margin: 0.75rem 0.25rem;
  padding: 0;
  width: 75%;
}

.btn {
  background-color: $red;
  border: 1px solid $red;
  border-radius: 6px;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  margin: 2rem 25% 0;
  padding: 0.25rem 0.5rem;

  &:focus {
    outline: none;
  }
}

@media (max-width: 60rem) {
  #contact-form {
    margin: 0;
  }
}

@media (max-width: 30rem) {
  #contact-form {
    margin: 1rem 0 0;
    width: 100%;
  }

  .btn {
    margin-top: 1rem;
  }
}
</style>
