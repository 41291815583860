<template>
  <div id="our-service-icon" class="flex column">
    <img :src="iconId" class="icon" />
    <p class="title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'OurServiceIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    iconId: function() {
      return require(`@/assets/images/${this.icon}.svg`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#our-service-icon {
  margin: 0 2%;
  width: 20%;
}

.icon {
  height: 4rem;
  width: 100%;
}

.title {
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 60rem) {
  .icon {
    height: 3rem;
  }
}

@media (max-width: 30rem) {
  #our-service-icon {
    width: auto;
  }
}
</style>
