export default [
  {
    name: 'California',
    color: '#CD0F27',
    sections: [
      {
        name: 'Corporación',
        points: [
          'Legislación: Código de corporaciones.',
          'Clasificación: Corporación Empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'No aplican restricciones.',
          'Capital Social: Suscrito, pero no pagado. No hay cantidad mínima a suscribir. Establecida en los estatuos.',
          'Responsabilidad limitada a la empresa.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Diferentes tipos de acciones.',
          'Estructura organizacional: Único administrador o junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado.',
          'Quórum: Mayoría accionaria con derecho de voto, salvo pacto en contrario.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: - Impuesto mensual, prepago trimestral, trimestral o anual, en año natural o año fiscal. - Impuesto renta anual. - Impuesto de franquicia.'
        ]
      },
      {
        name: 'Sociedad de responsabilidad limitada',
        points: [
          'Legislación: Código de corporaciones (Ley de California uniforme revisada LLC).',
          'Clasificación: Corporación Empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'No aplican restricciones.',
          'Capital Social: - No es necesario suscribir. - No hay cantidad mínima en caso de que se suscriba.',
          'Responsabilidad limitada a la empresa.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Acciones: "Member units" y hay distintos tipo de miembros.',
          'Estructura organizacional: - Socios administradores; Administradores que no son socios; o junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado',
          'Quorum: Mayoría de los miembros, salvo pacto en contrario.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: - Impuesto sobre venta mensual, prepago trimestral, trimestral o anual, en año natural o año fiscal. - Impuesto renta anual. - Impuesto de franquicia.'
        ]
      },
      {
        name: 'Corporación sin Fines de Lucro',
        points: [
          'Legislación: Código de corporaciones (Ley de Sociedades sin Fines de Lucro).',
          'Clasificación: Corporación sin fines de lucro.',
          'Mínimo de socios o accionistas: No hay socios o accionistas.',
          'Restricciones: El nombre de la organización no podrá incluir las palabras "banco", "fideicomiso" o similares sin la aprobación del comisionado de instituciones financieras.',
          'Capital Social: No se suscribe.',
          'Sin responsabilidad personal a directores y ejecutivos (actuando en buena fe). Los daños serán cubiertos de conformidad con una póliza de seguros.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'No hay acciones.',
          'Estructura organizacional: Junta directiva.',
          'Constitución: Trámite y archivo de los artículos de incorporación ante la secretaría de estado.',
          'Quorum: Una tercera parte de los votos con derecho a ser emitidos, a menos que se establezca lo contrario en los estatutos.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: - Sin ingresos o impuesto sobre las ventas.'
        ]
      },
      {
        name: 'Corporación Profesional',
        points: [
          'Legislación: Código de corporaciones.',
          'Clasificación: Corporación empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'Restricciones: Todos los socios deben ejercer la misma profesión y de acuerdo a los servicios ofrecidos por la organización.',
          'Capital Social: - Suscrito, pero no pagado. No hay cantidad mínima a suscribir. Establecida en los estatutos.',
          'Responsabilidad personal.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Acciones: Diferentes tipos con restricción en su transferencia.',
          'Estructura organizacional: - Único administrador; o Junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado.',
          'Quorum: Mayoría accionaria, salvo pacto en contrario.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: Impuesto sobre venta mensual, prepago trimestral, trimestral o anual, en año natural o año fiscal. - Impuesto renta anual.'
        ]
      }
    ]
  },
  {
    name: 'Nueva York',
    color: '#258135',
    sections: [
      {
        name: 'Corporación',
        points: [
          'Legislación: Ley de Corporaciones Comerciales.',
          'Clasificación: Corporación Empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'No aplican restricciones.',
          'Capital Social: Suscrito, pero no pagado. No hay cantidad mínima a suscribir. Establecida en los estatutos.',
          'Responsabilidad limitada a los activos de la empresa.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Diferentes tipos de acciones.',
          'Estructura organizacional: Único administrador o junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado.',
          'Quorum: Mayoría de las acciones con derecho a voto, salvo pacto en contrario.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: - Impuesto mensual, trimestral o anual, en año natural o fiscal. - Impuesto Renta Anual. - Impuesto de Franquicia.'
        ]
      },
      {
        name: 'Sociedad de Responsabilidad Limitada',
        points: [
          'Legislación: Ley de Sociedades de Responsabilidad Limitada.',
          'Clasificación: Corporación Empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'No aplican restricciones.',
          'Capital Social: - No es necesario suscribir. - No hay cantidad mínima en caso de que se suscriba.',
          'Responsabilidad limitada a los activos de la empresa.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Acciones: No hay acciones, clasificación de miembros.',
          'Estructura organizacional: - Socios administradores; Administradores que no son socios; o junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado',
          'Quorum: Mayoría de los miembros con derecho a voto, salvo pacto en contrario.',
          'Modificación de estatutuos: Decisión de los socios.',
          'Impuestos aplicables: - Impuesto mensual, prepago trimestral, trimestral o anual, en año natural o año fiscal. - Impuesto Renta Anual. - Impuesto de Franquicia.'
        ]
      },
      {
        name: 'Corporación sin Fines de Lucro',
        points: [
          'Legislación: Ley de Sociedades Anónimas sin Fines de Lucro.',
          'Clasificación: Corporación sin fines de lucro.',
          'Mínimo de socios o accionistas: 1.',
          'Restricciones: El nombre de la organización no podrá incluir las palabras "banco", "fideicomiso" o similares sin la aprobación del comisionado de instituciones financieras.',
          'Capital Social: No se suscribe.',
          'Sin responsabilidad personal a directores y ejecutivos (actuando en buena fe).',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'No hay acciones.',
          'Estructura organizacional: Junta directiva.',
          'Constitución: Trámite y archivo de los artículos de incorporación ante la secretaría de estado.',
          'Quorum: Mayoría del número total de votos con derecho a emitirse, salvo pacto en contrario',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: - Sin ingresos o impuesto sobre las ventas.'
        ]
      },
      {
        name: 'Corporación Profesional',
        points: [
          'Legislación: Ley de Corporaciones Comerciales.',
          'Clasificación: Corporación empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'Restricciones: Todos los socios deben ejercer la misma profesión y de acuerdo a los servicios ofrecidos por la organización.',
          'Capital Social: - Suscrito, pero no pagado. No hay cantidad mínima a suscribir. Establecida en los estatutos.',
          'Responsabilidad personal.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Acciones: Diferentes tipos con restricción en su transferencia.',
          'Estructura organizacional: - Único administrador; o junta directiva.',
          'Constitución: Trámite del certificado de incorporación ante el departamento del estado.',
          'Quorum: Mayoría de las acciones con derecho a voto, salvo pacto en contrario.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: Impuesto mensual, trimestral o anual, en año natural o año fiscal. - Impuesto Renta Anual.'
        ]
      }
    ]
  },
  {
    name: 'Texas',
    color: '#EBC01B',
    sections: [
      {
        name: 'Corporación',
        points: [
          'Legislación: Código de organización empresarial.',
          'Clasificación: Corporación Empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'No aplican restricciones.',
          'Capital Social: Suscrito, pero no pagado. No hay cantidad mínima a suscribir. Establecida en los estatutos.',
          'Responsabilidad limitada a los activos de la empresa.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Diferentes tipos de acciones.',
          'Estructura organizacional: Único administrador o junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado.',
          'Quorum: Mayoría accionaria con derecho de voto.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: - Impuesto sobre las ventas mensual, trimestral o anual, en año natural o año fiscal. - Impuesto sobre renta mensual. - Impuesto de Franquicia.'
        ]
      },
      {
        name: 'Sociedad de responsabilidad limitada',
        points: [
          'Legislación: Código de organización empresarial.',
          'Clasificación: Corporación Empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'No aplican restricciones.',
          'Capital Social: - No es necesario suscribir. - No hay cantidad mínima en caso de que se suscriba.',
          'Responsabilidad limitada a los activos de la empresa.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Acciones: No hay acciones, clasificación de miembros.',
          'Estructura organizacional: - Socios administradores; Administradores que no son socios; o junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado',
          'Quorum: Mayoría de los miembros, salvo pacto en contrario.',
          'Modificación de estatutuos: Decisión de los socios.',
          'Impuestos aplicables: - Impuesto sobre las ventas mensual, prepago trimestral, trimestral o anual, en año natural o año fiscal. - Impuesto sobre renta anual. - Impuesto de Franquicia.'
        ]
      },
      {
        name: 'Corporación sin Fines de Lucro',
        points: [
          'Legislación: Código de corporaciones (Ley de sociedades sin fines de lucro).',
          'Clasificación Fundación privada o caridad pública.',
          'Mínimo de socios o accionistas: No hay socios o accionistas.',
          'Restricciones: No se pagan dividendos.',
          'Capital Social: No se suscribe.',
          'Sin responsabilidad personal a directores y ejecutivos (actuando en buena fe). Los daños serán cubiertos de conformidad con una póliza de seguros.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'No hay acciones.',
          'Estructura organizacional: Junta directiva.',
          'Constitución: Trámite y archivo de los artículos de incorporación ante la secretaría de estado.',
          'Quorum: Una décima parte de los votos con derecho a ser emitidos, a menos que se establezca lo contrario en el certificado de formación o en los estatutos.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: - Sin ingresos o impuesto sobre las ventas.'
        ]
      },
      {
        name: 'Corporación Profesional',
        points: [
          'Legislación: Código de corporaciones.',
          'Clasificación: Corporación empresarial.',
          'Mínimo de socios o accionistas: 1.',
          'Restricciones: Todos los socios deben ejercer la misma profesión y de acuerdo a los servicios ofrecidos por la organización.',
          'Capital Social: - Suscrito, pero no pagado. No hay cantidad mínima a suscribir. Establecida en los estatutos.',
          'Cada socio es responsable por su negligencia y responsabilidad profesional.',
          'Cualquier objeto que sea legal.',
          'Tiempo de vida definido o indefinido.',
          'Acciones: Diferentes tipos con restricción en su transferencia.',
          'Estructura organizacional: - Único administrador; o Junta directiva.',
          'Constitución: Trámite del certificado de formación ante la secretaría del estado.',
          'Quorum: Mayoría accionaria, salvo pacto en contrario.',
          'Modificación de estatutos: Decisión de los socios.',
          'Impuestos aplicables: Impuesto sobre las ventas mensual, trimestral o anual, en año natural o año fiscal. - Impuesto sobre renta anual.'
        ]
      }
    ]
  }
]
