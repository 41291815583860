<template>
  <div id="review">
    <h1>{{ $t('payForm.titles.review') }}</h1>
    <div class="section">
      <div class="information-row"><span class="bold">{{ $t('payForm.companyName') }}:</span> {{ order.companyName }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.email') }}:</span> {{ order.userEmail }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.name') }}:</span> {{ order.userName }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.phone') }}:</span> {{ order.userPhone }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.address') }}:</span> {{ order.userAddress }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.country') }}:</span> {{ order.userCountry }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.zipCode') }}:</span> {{ order.userZipcode }}</div>
    </div>
    <h1>{{ $t('payForm.titles.companyDetails') }}</h1>
    <div class="section">
      <h2>{{ $t('payForm.titles.management') }}</h2>
      <div v-for="(manager, index) in order.companyManagers" :key="index">
        <div class="information-row"><span class="bold">{{ $t('payForm.name') }}:</span> {{ manager.name }}</div>
        <div class="information-row"><span class="bold">{{ $t('payForm.address') }}:</span> {{ manager.address }}</div>
        <hr class="divider" />
      </div>
    </div>
    <div class="section">
      <h2>{{ $t('payForm.titles.capital') }}</h2>
      <div v-for="(share, index) in order.companyShares" :key="index">
        <div class="information-row"><span class="bold">{{ $t('payForm.numberOfShares') }}:</span> {{ share.shares }}</div>
        <div v-if="share.shareType === 0" class="information-row"><span class="bold">{{ $t('payForm.hasParValue') }}:</span> {{ share.parValue }}</div>
        <div v-if="share.shareType === 1" class="information-row">
          <span class="bold">{{ $t('payForm.withoutParValue') }}</span> | <span class="bold">{{ $t('payForm.class') }}:</span> {{ share.shareClass }} - <span class="bold">{{ $t('payForm.series') }}:</span> {{ share.series }}</div>
        <hr class="divider" />
      </div>
    </div>
    <div class="flex justify-flex-end">
      <button class="btn white" @click.prevent="$emit('previousStep')">{{ $t('payForm.back') }}</button>
      <button class="btn black" @click.prevent="goToNextStep">{{ $t('payForm.next') }}</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Review',
  computed: {
    ...mapState(['order'])
  },
  methods: {
    goToNextStep() {
      if (this.order.productId && this.order.stateCommissionId) {
        this.$emit('nextStep')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#review {
  text-align: left;
}

.section {
  margin: 2rem 0;
}

.information-row {
  margin: 0.25rem 0;
}

.bold {
  font-weight: bold;
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
}

.btn {
  border: 0;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 12px 0;
  padding: 0.6rem 1.5rem;

  &:focus {
    outline-width: 0;
  }

  &.cancel {
    background-color: #ffffff;
    color: $black;
  }

  &.white {
    background-color: #ffffff;
    border: 1px solid $grey;
    color: $black;
    margin: auto 2rem;
  }

  &.black {
    background-color: $black;
    border: 1px solid $black;
    color: $white;
  }
}
</style>
