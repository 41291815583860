<template>
  <div id="app">
    <LoadingModal />
    <ErrorModal />
    <SuccessModal />
    <TheMobileSidebar />
    <div @click="closeSidebar">
      <TheHeader />
      <router-view />
      <TheFooter />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ErrorModal from '@/components/ErrorModal'
import SuccessModal from '@/components/SuccessModal'
import LoadingModal from '@/components/LoadingModal'
import TheMobileSidebar from '@/components/TheMobileSidebar'
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'

export default {
  name: 'App',
  components: {
    ErrorModal,
    SuccessModal,
    LoadingModal,
    TheMobileSidebar,
    TheHeader,
    TheFooter
  },
  computed: {
    ...mapState('app', ['errorModalVisible', 'successModalVisible'])
  },
  methods: {
    closeSidebar() {
      const sidebar = document.getElementById('the-mobile-sidebar')

      if (sidebar.classList.contains('active')) {
        sidebar.classList.add('animate-out')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#app {
  color: $black;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    color: #2c3e50;
    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
