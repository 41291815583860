import i18n from '@/i18n'
import { postOrder } from '@/api/order'

const getDefaultState = () => {
  return {
    productId: '',
    companyName: '',
    companyType: 0,
    stateCommissionId: '',
    userEmail: '',
    userName: '',
    userPhone: '',
    userAddress: '',
    userCountry: '',
    userZipcode: '',
    companyManagers: [{
      name: '',
      address: ''
    }],
    companyShares: [{
      shares: '',
      shareType: 0,
      parValue: '',
      shareClass: '',
      series: ''
    }],
    cardToken: ''
  }
}

const state = getDefaultState()

const mutations = {
  UPDATE_PRODUCT_ID: (state, value) => {
    state.productId = value
  },
  UPDATE_COMPANY_TYPE: (state, value) => {
    state.companyType = value
  },
  UPDATE_STATE_COMMISSION_ID: (state, value) => {
    state.stateCommissionId = value
  },
  UPDATE_COMPANY_NAME: (state, value) => {
    state.companyName = value
  },
  UPDATE_USER_EMAIL: (state, value) => {
    state.userEmail = value
  },
  UPDATE_USER_NAME: (state, value) => {
    state.userName = value
  },
  UPDATE_USER_PHONE: (state, value) => {
    state.userPhone = value
  },
  UPDATE_USER_ADDRESS: (state, value) => {
    state.userAddress = value
  },
  UPDATE_USER_COUNTRY: (state, value) => {
    state.userCountry = value
  },
  UPDATE_USER_ZIPCODE: (state, value) => {
    state.userZipcode = value
  },
  ADD_COMPANY_MANAGER: (state) => {
    state.companyManagers.push({
      name: '',
      address: ''
    })
  },
  UPDATE_COMPANY_MANAGERS: (state, managers) => {
    state.companyManagers = [...managers]
  },
  ADD_COMPANY_SHARE: (state) => {
    state.companyShares.push({
      shares: 0,
      shareType: 0,
      parValue: 0,
      shareClass: '',
      series: ''
    })
  },
  UPDATE_COMPANY_SHARES: (state, shares) => {
    state.companyShares = [...shares]
  },
  UPDATE_CARD_TOKEN: (state, value) => {
    state.cardToken = value
  }
}

const actions = {
  updateProductId({ commit }, value) {
    localStorage.setItem('orderProductId', value)
    commit('UPDATE_PRODUCT_ID', value)
  },
  updateCompanyType({ commit }, value) {
    commit('UPDATE_COMPANY_TYPE', value)
  },
  updateStateCommissionId({ commit }, value) {
    localStorage.setItem('orderStateCommissionId', value)
    commit('UPDATE_STATE_COMMISSION_ID', value)
  },
  updateCompanyName({ commit }, value) {
    commit('UPDATE_COMPANY_NAME', value)
  },
  updateUserEmail({ commit }, value) {
    commit('UPDATE_USER_EMAIL', value)
  },
  updateUserName({ commit }, value) {
    commit('UPDATE_USER_NAME', value)
  },
  updateUserPhone({ commit }, value) {
    commit('UPDATE_USER_PHONE', value)
  },
  updateUserAddress({ commit }, value) {
    commit('UPDATE_USER_ADDRESS', value)
  },
  updateUserCountry({ commit }, value) {
    commit('UPDATE_USER_COUNTRY', value)
  },
  updateUserZipcode({ commit }, value) {
    commit('UPDATE_USER_ZIPCODE', value)
  },
  updateCardToken({ commit }, value) {
    commit('UPDATE_CARD_TOKEN', value)
  },
  addCompanyManager({ commit }) {
    commit('ADD_COMPANY_MANAGER')
  },
  removeCompanyManager({ commit }, index) {
    const managers = [...state.companyManagers]
    managers.splice(index, 1)

    commit('UPDATE_COMPANY_MANAGERS', managers)
  },
  updateCompanyManagerName({ commit }, { index, value }) {
    const managers = [...state.companyManagers]
    const selectedManager = { ...managers[index] }

    selectedManager.name = value
    managers[index] = selectedManager

    commit('UPDATE_COMPANY_MANAGERS', managers)
  },
  updateCompanyManagerAddress({ commit }, { index, value }) {
    const managers = [...state.companyManagers]
    const selectedManager = { ...managers[index] }

    selectedManager.address = value
    managers[index] = selectedManager

    commit('UPDATE_COMPANY_MANAGERS', managers)
  },
  addCompanyShare({ commit }) {
    commit('ADD_COMPANY_SHARE')
  },
  removeCompanyShare({ commit }, index) {
    const shares = [...state.companyShares]
    shares.splice(index, 1)

    commit('UPDATE_COMPANY_SHARES', shares)
  },
  updateCompanySharesAmount({ commit }, { index, value }) {
    const shares = [...state.companyShares]
    const selectedShare = { ...shares[index] }

    selectedShare.shares = value
    shares[index] = selectedShare

    commit('UPDATE_COMPANY_SHARES', shares)
  },
  updateCompanySharesType({ commit }, { index, value }) {
    const shares = [...state.companyShares]
    const selectedShare = { ...shares[index] }

    selectedShare.shareType = value
    shares[index] = selectedShare

    commit('UPDATE_COMPANY_SHARES', shares)
  },
  updateCompanySharesParValue({ commit }, { index, value }) {
    const shares = [...state.companyShares]
    const selectedShare = { ...shares[index] }

    selectedShare.parValue = value
    shares[index] = selectedShare

    commit('UPDATE_COMPANY_SHARES', shares)
  },
  updateCompanySharesClass({ commit }, { index, value }) {
    const shares = [...state.companyShares]
    const selectedShare = { ...shares[index] }

    selectedShare.shareClass = value
    shares[index] = selectedShare

    commit('UPDATE_COMPANY_SHARES', shares)
  },
  updateCompanySharesSeries({ commit }, { index, value }) {
    const shares = [...state.companyShares]
    const selectedShare = { ...shares[index] }

    selectedShare.series = value
    shares[index] = selectedShare

    commit('UPDATE_COMPANY_SHARES', shares)
  },
  trimCompanyInfo({ commit }) {
    commit('UPDATE_COMPANY_NAME', state.companyName.trim())
    commit('UPDATE_USER_EMAIL', state.userEmail.trim())
    commit('UPDATE_USER_NAME', state.userName.trim())
    commit('UPDATE_USER_PHONE', state.userPhone.trim())
    commit('UPDATE_USER_ADDRESS', state.userAddress.trim())
    commit('UPDATE_USER_ZIPCODE', state.userZipcode.trim())
  },
  trimCompanyManagersInfo({ commit, dispatch }) {
    state.companyManagers.forEach((manager, index) => {
      dispatch('updateCompanyManagerName', {
        index,
        value: manager.name.trim()
      })
      dispatch('updateCompanyManagerAddress', {
        index,
        value: manager.address.trim()
      })
    })
  },
  trimCompanySharesInfo({ commit, dispatch }) {
    state.companyShares.forEach((companyShare, index) => {
      dispatch('updateCompanySharesAmount', {
        index,
        value: companyShare.shares.trim()
      })

      if (companyShare.shareType === 0) {
        dispatch('updateCompanySharesParValue', {
          index,
          value: companyShare.parValue.trim()
        })
      }
      else {
        dispatch('updateCompanySharesClass', {
          index,
          value: companyShare.shareClass.trim()
        })
        dispatch('updateCompanySharesSeries', {
          index,
          value: companyShare.series.trim()
        })
      }
    })
  },
  createOrder({ commit }) {
    return postOrder({ order: state }).then(response => {
      return response
    }).catch(error => {
      throw error
    })
  }
}

const getters = {
  companyNameErrors: state => {
    const errors = []
    const minLength = 5

    if (!state.companyName.length) {
      errors.push(i18n.t('formErrors.notEmpty'))
    }
    if (state.companyName.length < minLength) {
      errors.push(i18n.t('formErrors.minCharacters', { count: minLength }))
    }

    return errors
  },
  userEmailErrors: state => {
    const errors = []
    const validFormat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

    if (!state.userEmail.length) {
      errors.push(i18n.t('formErrors.notEmpty'))
    }
    if (!validFormat.test(state.userEmail)) {
      errors.push(i18n.t('formErrors.validFormat', { format: 'email@example.com' }))
    }

    return errors
  },
  userNameErrors: state => {
    const errors = []
    const minLength = 5

    if (!state.userName.length) {
      errors.push(i18n.t('formErrors.notEmpty'))
    }
    if (state.userName.length < minLength) {
      errors.push(i18n.t('formErrors.minCharacters', { count: minLength }))
    }

    return errors
  },
  userPhoneErrors: state => {
    const errors = []
    const minLength = 5

    if (!state.userPhone.length) {
      errors.push(i18n.t('formErrors.notEmpty'))
    }
    if (state.userPhone.length < minLength) {
      errors.push(i18n.t('formErrors.minCharacters', { count: minLength }))
    }

    return errors
  },
  userAddressErrors: state => {
    const errors = []
    const minLength = 5

    if (!state.userAddress.length) {
      errors.push(i18n.t('formErrors.notEmpty'))
    }
    if (state.userAddress.length < minLength) {
      errors.push(i18n.t('formErrors.minCharacters', { count: minLength }))
    }

    return errors
  },
  userCountryErrors: state => {
    const errors = []

    if (!state.userCountry.length) {
      errors.push(i18n.t('formErrors.notEmpty'))
    }

    return errors
  },
  userZipcodeErrors: state => {
    const errors = []
    const maxLength = 6
    const validNumbers = /^[0-9]*$/

    if (!state.userZipcode.length) {
      errors.push(i18n.t('formErrors.notEmpty'))
    }
    if (!validNumbers.test(state.userZipcode)) {
      errors.push(i18n.t('formErrors.numericValues'))
    }
    if (state.userZipcode.length > maxLength) {
      errors.push(i18n.t('formErrors.maxCharacters', { count: maxLength }))
    }

    return errors
  },
  companyManagersErrors: state => {
    const errors = []
    const minLength = 5

    state.companyManagers.forEach((manager, index) => {
      errors.push({ name: [], address: [] })

      if (!manager.name.length) {
        errors[index].name.push(i18n.t('formErrors.notEmpty'))
      }
      if (manager.name.length < minLength) {
        errors[index].name.push(i18n.t('formErrors.minCharacters', { count: minLength }))
      }
      if (!manager.address.length) {
        errors[index].address.push(i18n.t('formErrors.notEmpty'))
      }
      if (manager.address.length < minLength) {
        errors[index].address.push(i18n.t('formErrors.minCharacters', { count: minLength }))
      }
    })

    return errors
  },
  companySharesErrors: state => {
    const errors = []
    const validNumbers = /^[0-9]*$/

    state.companyShares.forEach((companyShare, index) => {
      errors.push({
        shares: [],
        parValue: [],
        shareClass: [],
        series: []
      })

      if (!companyShare.shares.length) {
        errors[index].shares.push(i18n.t('formErrors.notEmpty'))
      }
      if (!validNumbers.test(companyShare.shares)) {
        errors[index].shares.push(i18n.t('formErrors.numericValues'))
      }
      if (companyShare.shareType === 0 && !companyShare.parValue.length) {
        errors[index].parValue.push(i18n.t('formErrors.notEmpty'))
      }
      if (companyShare.shareType === 0 && !validNumbers.test(companyShare.parValue)) {
        errors[index].parValue.push(i18n.t('formErrors.numericValues'))
      }
      if (companyShare.shareType === 1 && !companyShare.shareClass.length) {
        errors[index].shareClass.push(i18n.t('formErrors.notEmpty'))
      }
      if (companyShare.shareType === 1 && !companyShare.series.length) {
        errors[index].series.push(i18n.t('formErrors.notEmpty'))
      }
    })

    return errors
  },
  isCompanyStepValid: (state, getters) => {
    return (
      !getters.companyNameErrors.length &&
      !getters.userEmailErrors.length &&
      !getters.userNameErrors.length &&
      !getters.userPhoneErrors.length &&
      !getters.userAddressErrors.length &&
      !getters.userCountryErrors.length &&
      !getters.userZipcodeErrors.length
    )
  },
  isManagementStepValid: (state, getters) => {
    const managersErrors = [...getters.companyManagersErrors]

    return managersErrors.reduce((pastValidation, managerErrors) => {
      return pastValidation && !managerErrors.name.length && !managerErrors.address.length
    }, true)
  },
  isCapitalStepValid: (state, getters) => {
    const companySharesErrors = [...getters.companySharesErrors]

    return companySharesErrors.reduce((pastValidation, shareErrors) => {
      return pastValidation && !shareErrors.shares.length &&
        !shareErrors.parValue.length && !shareErrors.shareClass.length &&
        !shareErrors.series.length
    }, true)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
