<template>
  <div id="podcast-banner" class="dspl-grid flex-sm column-sm">
    <img src="@/assets/images/Icono-Spotify.svg" alt="spotify icon" class="icon" />
    <a href="https://open.spotify.com/episode/0FQbaEZBQvplvs4bpKkYhN" target="_blank" class="link">
      <h1 class="title">¿Cómo abrir mi PYME en EUA en menos de 48 horas?</h1>
    </a>
    <img src="@/assets/images/Icono-Youtube.svg" alt="youtube icon" class="icon" />
  </div>
</template>

<script>
export default {
  name: 'PodcastBanner'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#podcast-banner {
  background-color: $light-grey;
  grid-template-columns: 25% 50% 25%;
  margin-top: 5rem;
  padding: 2rem 0;
  width: 100%;
}

.title {
  font-size: 2.5rem;
  margin: auto 2rem;
}

.icon {
  align-self: center;
  height: 2.5rem;

  &:nth-child(1) {
    justify-self: end;
  }
}

.link {
  color: $black;
  text-decoration: none;
}

@media (max-width: 30rem) {
  .icon {
    margin: 1rem 0;
  }
}
</style>
