<template>
  <div id="payment-form">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PaymentForm'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#payment-form {
  border: 1px solid $black;
  margin-top: 3rem;
  padding: 0 4rem 0 1rem;
  width: 100%;
}

@media (max-width: 30rem) {
  #payment-form {
    padding: 0 1rem;
  }
}
</style>
