<template>
  <form id="management">
    <h1>{{ $t('payForm.titles.management') }}</h1>
    <div v-for="(manager, index) in managers" :key="index">
      <div class="flex align-center column-sm align-stretch-sm">
        <span class="input-label">{{ $t('payForm.managerName') }}</span>
        <div class="grow-1">
          <input
            @input="updateName($event, index)"
            :value="manager.name"
            type="text"
            name="name"
            class="text-input"
            required
          />
          <div v-if="showErrors && companyManagersErrors[index].name.length" class="error-msg">
            {{ companyManagersErrors[index].name.join(' - ') }}
          </div>
        </div>
      </div>
      <div class="flex align-center column-sm align-stretch-sm">
        <span class="input-label">{{ $t('payForm.address') }}</span>
        <div class="grow-1">
          <input
            @input="updateAddress($event, index)"
            :value="manager.address"
            type="text"
            name="address"
            class="text-input grow-1"
            required
          />
          <div v-if="showErrors && companyManagersErrors[index].address.length" class="error-msg">
            {{ companyManagersErrors[index].address.join(' - ') }}
          </div>
        </div>
      </div>
      <button v-if="index > 0" @click.prevent="removeCompanyManager(index)" class="btn white delete">
        {{ $t('payForm.remove') }}
      </button>
      <hr class="divider"/>
    </div>
    <button @click.prevent="addCompanyManager" class="btn cancel">
      {{ $t('payForm.add') }} +
    </button>
    <div class="flex justify-flex-end">
      <button class="btn white" @click.prevent="$emit('previousStep')">{{ $t('payForm.back') }}</button>
      <button class="btn black" @click.prevent="nextStep">{{ $t('payForm.next') }}</button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Management',
  data() {
    return {
      showErrors: false
    }
  },
  computed: {
    ...mapState({
      managers: state => state.order.companyManagers
    }),
    ...mapState('order', [
      'productId',
      'stateCommissionId'
    ]),
    ...mapGetters('order', [
      'isManagementStepValid',
      'companyManagersErrors'
    ])
  },
  methods: {
    ...mapActions('order', [
      'addCompanyManager',
      'removeCompanyManager',
      'updateCompanyManagerName',
      'updateCompanyManagerAddress',
      'trimCompanyManagersInfo'
    ]),
    updateName(e, index) {
      this.updateCompanyManagerName({ index, value: e.target.value })
    },
    updateAddress(e, index) {
      this.updateCompanyManagerAddress({ index, value: e.target.value })
    },
    nextStep() {
      this.showErrors = true

      if (this.isManagementStepValid) {
        this.trimCompanyManagersInfo()
        this.$emit('nextStep')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#management {
  text-align: left;
}

.input-label {
  max-width: 10rem;
  min-width: 10rem;
  text-align: left;
}

.text-input {
  background-color: $light-grey;
  border-width: 0;
  box-sizing: border-box;
  font-size: 1rem;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;

  &:focus {
    outline-width: 0;
  }
}

.error-msg {
  color: $red;
  font-size: 0.75rem;
  margin-top: -1rem;
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
}

.btn {
  border: 0;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 12px 0;
  padding: 0.6rem 1.5rem;

  &:focus {
    outline-width: 0;
  }

  &.cancel {
    background-color: #ffffff;
    color: $black;
  }

  &.white {
    background-color: #ffffff;
    border: 1px solid $grey;
    color: $black;
    margin: auto 2rem;
  }

  &.black {
    background-color: $black;
    border: 1px solid $black;
    color: $white;
  }

  &.delete {
    display: block;
    margin: auto;
    margin-top: 1.25rem;
  }
}

@media (max-width: 30rem) {
  .input-label {
    max-width: 9rem;
    min-width: 9rem;
  }

  .text-input {
    margin: 0.25rem 0 2rem;
  }

  .error-msg {
    margin-bottom: 1.5rem;
    margin-top: -1.75rem;
  }
}
</style>
