<template>
  <div id="order-preview">
    <div v-if="selectedProduct && selectedStateCommission">
      <h2 class="title">Plan {{ selectedProduct[$i18n.locale].name }}</h2>
      <div class="flex align-center column-sm align-start-sm">
        <h1 class="price-title"><span class="currency">USD</span>{{ selectedProduct.formattedAmount }}</h1>
        <p class="price" v-if="selectedProduct.subscriptionAmount">
          + USD {{ selectedProduct.subscriptionFormattedAmount }}
          <span class="disclaimer">/{{ $tc('month', 0) }} {{ $t('for') }} {{ $tc('month', selectedProduct.subscriptionMonths) }}</span>
        </p>
        <p class="price">+ USD {{ selectedStateCommission.formattedAmount }} <span class="disclaimer">({{ $t('stateCommission') }})</span></p>
      </div>
    </div>
    <p v-else>Debes seleccionar un plan, tipo de empresa y estado de la empresa</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrderPreview',
  computed: {
    ...mapGetters('products', [
      'selectedProduct'
    ]),
    ...mapGetters('stateCommissions', [
      'selectedStateCommission'
    ])
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#order-preview {
  background-color: $black;
  color: $white;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  text-align: left;
}

.title {
  margin-bottom: 0;
}

.price-title {
  color: $yellow;
  font-size: 3.5rem;
  margin: 0 2rem 0 0;
}

.currency {
  font-size: 0.75rem;
  vertical-align: middle;
}

.price {
  font-size: 1.25rem;
  margin: 0 0.25rem;
}

.disclaimer {
  font-size: 0.75rem;
  vertical-align: middle;
}

@media (max-width: 30rem) {
  #order-preview {
    padding: 1rem;
  }

  .title {
    margin: 0.5rem 0;
  }

  .price {
    margin: 0.5rem 0;
  }
}
</style>
