import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/modules/app'
import order from '@/store/modules/order'
import products from '@/store/modules/products'
import stateCommissions from '@/store/modules/stateCommissions'
import errors from '@/store/modules/errors'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    order,
    products,
    stateCommissions,
    errors
  }
})
