<template>
  <div id="about-us">
    <CorporativeOrganization />
    <CompanyHistory />
    <Certifications />
  </div>
</template>

<script>
import CorporativeOrganization from '@/components/CorporativeOrganization'
import CompanyHistory from '@/components/CompanyHistory'
import Certifications from '@/components/Certifications'

export default {
  name: 'AboutUs',
  components: {
    CorporativeOrganization,
    CompanyHistory,
    Certifications
  }
}
</script>
