<template>
  <form id="capital">
    <h1>{{ $t('payForm.titles.capital') }}</h1>
    <div v-for="(share, index) in shares" :key="index">
      <div class="flex align-center column-sm align-stretch-sm">
        <label class="input-label">{{ $t('payForm.numberOfShares') }}</label>
        <div class="grow-1">
          <input
            @input="updateNumberOfShares($event, index)"
            :value="share.shares"
            type="text"
            class="text-input"
            required
          />
          <div v-if="showErrors && companySharesErrors[index].shares.length" class="error-msg">
            {{ companySharesErrors[index].shares.join(' - ') }}
          </div>
        </div>
      </div>
      <div class="share-type-disclaimer">{{ $t('payForm.shareParValueDisclaimer') }}</div>
      <div class="flex align-center wrap-sm submenu">
        <input
          @change="updateShareType($event, index)"
          :checked="share.shareType === 0"
          type="radio"
          value="0"
          class="radio-button"
        />
        <label class="input-label">A) {{ $t('payForm.hasParValue') }}</label>
        <div class="grow-1">
          <input
            @input="updateShareParValue($event, index)"
            :value="share.parValue"
            type="text"
            class="text-input"
            required
          />
          <div v-if="showErrors && companySharesErrors[index].parValue.length" class="error-msg">
            {{ companySharesErrors[index].parValue.join(' - ') }}
          </div>
        </div>
      </div>
      <div class="flex align-baseline wrap-sm submenu">
        <input
          @change="updateShareType($event, index)"
          :checked="share.shareType === 1"
          type="radio"
          value="1"
          class="radio-button"
        />
        <label class="input-label">B) {{ $t('payForm.withoutParValue') }}</label>
        <div class="short-input-group">
          <div>
            <input
              @input="updateShareClass($event, index)"
              :value="share.shareClass"
              type="text"
              class="text-input"
              required
            />
            <div v-if="showErrors && companySharesErrors[index].shareClass.length" class="error-msg">
              {{ companySharesErrors[index].shareClass.join(' - ') }}
            </div>
          </div>
          <p class="input-help">{{ $t('payForm.class') }}</p>
        </div>
        <div class="short-input-group">
          <div>
            <input
              @input="updateShareSeries($event, index)"
              :value="share.series"
              type="text"
              class="text-input"
              required
            />
            <div v-if="showErrors && companySharesErrors[index].series.length" class="error-msg">
              {{ companySharesErrors[index].series.join(' - ') }}
            </div>
          </div>
          <p class="input-help">{{ $t('payForm.series') }}</p>
        </div>
      </div>
      <button v-if="index > 0" @click.prevent="removeCompanyShare(index)" class="btn white delete">
        {{ $t('payForm.remove') }}
      </button>
      <hr class="divider" />
    </div>
    <button @click.prevent="addCompanyShare" class="btn cancel">
      {{ $t('payForm.add') }} +
    </button>
    <div class="flex justify-flex-end">
      <button class="btn white" @click.prevent="$emit('previousStep')">{{ $t('payForm.back') }}</button>
      <button class="btn black" @click.prevent="nextStep">{{ $t('payForm.next') }}</button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Capital',
  data() {
    return {
      showErrors: false
    }
  },
  computed: {
    ...mapState({
      shares: state => state.order.companyShares
    }),
    ...mapState('order', [
      'productId',
      'stateCommissionId'
    ]),
    ...mapGetters('order', [
      'isCapitalStepValid',
      'companySharesErrors'
    ])
  },
  methods: {
    ...mapActions('order', [
      'addCompanyShare',
      'removeCompanyShare',
      'updateCompanySharesAmount',
      'updateCompanySharesType',
      'updateCompanySharesParValue',
      'updateCompanySharesClass',
      'updateCompanySharesSeries',
      'trimCompanySharesInfo'
    ]),
    updateNumberOfShares(e, index) {
      this.updateCompanySharesAmount({ index, value: e.target.value.replace(/^0/, '') })
    },
    updateShareType(e, index) {
      this.updateCompanySharesType({ index, value: parseInt(e.target.value) })
    },
    updateShareParValue(e, index) {
      this.updateCompanySharesParValue({ index, value: e.target.value })
    },
    updateShareClass(e, index) {
      this.updateCompanySharesClass({ index, value: e.target.value })
    },
    updateShareSeries(e, index) {
      this.updateCompanySharesSeries({ index, value: e.target.value })
    },
    nextStep() {
      this.showErrors = true

      if (this.productId && this.stateCommissionId && this.isCapitalStepValid) {
        this.trimCompanySharesInfo()
        this.$emit('nextStep')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';
@import '@/assets/styles/global';

#capital {
  text-align: left;
}

.input-label {
  max-width: 10rem;
  min-width: 10rem;
  text-align: left;
}

.text-input {
  background-color: $light-grey;
  border-width: 0;
  box-sizing: border-box;
  font-size: 1rem;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;

  &:focus {
    outline-width: 0;
  }
}

.error-msg {
  color: $red;
  font-size: 0.75rem;
  margin-top: -1rem;
}

.radio-button {
  margin: 0 0.5rem;
}

.share-type-disclaimer {
  margin-top: 1rem;
}

.submenu {
  margin-left: 1.5rem;

  .input-label {
    margin: 0.5rem 0;
  }

  .input-help {
    text-align: center;
  }
}

.short-input-group {
  width: 12rem;

  &:nth-child(3) {
    margin-right: auto;
  }

  &:nth-child(4) {
    margin-left: auto;
  }
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
}

.btn {
  border: 0;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 12px 0;
  padding: 0.6rem 1.5rem;

  &:focus {
    outline-width: 0;
  }

  &.cancel {
    background-color: #ffffff;
    color: $black;
  }

  &.white {
    background-color: #ffffff;
    border: 1px solid $grey;
    color: $black;
    margin: auto 2rem;
  }

  &.black {
    background-color: $black;
    border: 1px solid $black;
    color: $white;
  }

  &.delete {
    display: block;
    margin: auto;
  }
}

@media (max-width: 30rem) {
  .text-input {
    margin: 0.25rem 0 2rem;
  }

  .error-msg {
    margin-bottom: 1.5rem;
    margin-top: -1.75rem;
  }

  .submenu {
    margin-left: 0;
  }

  .short-input-group {
    width: 45%;
  }
}
</style>
