<template>
  <div id="locale-select" class="dspl-inline-block dspl-none-m">
    <span v-if="$i18n.locale === 'en'">English</span>
    <span v-if="$i18n.locale === 'es'">Español</span>
    <div class="dropdown-content dspl-none">
      <p @click="changeLocale('es')" v-if="$i18n.locale === 'en'">Español</p>
      <p @click="changeLocale('en')" v-if="$i18n.locale === 'es'">English</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocaleSelect',
  methods: {
    changeLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const currentRoute = this.$router.currentRoute.path
        const newRoute = currentRoute.replace(/^.{3}/g, `/${locale}`)

        this.$i18n.locale = locale
        this.$router.push({ path: newRoute })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

.dropdown-content {
  background-color: $black;
  left: -0.5rem;
  min-width: 5rem;
  position: absolute;
}

#locale-select {
  cursor: pointer;
  position: relative;
  width: 4rem;

  &:hover .dropdown-content {
    display: block;
  }
}
</style>
