<template>
  <div id="banner-home" class="flex align-center justify-center column-m">
    <img src="@/assets/images/Ilustracion-1-Wanderclyffe.svg" alt="publication icon" class="icon" />
    <div class="button-appointment flex column wrap justify-space-evenly-m align-start login-buttons align-center-m">
      <h1>{{ $t('home.hero.title') }}</h1>
      <h3>{{ $t('home.hero.subtitle') }}</h3>
      <button class="btn" @click.prevent="goToProducts">{{ $t('home.hero.button') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerHome',
  methods: {
    goToProducts() {
      document.getElementById('products-list').scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#banner-home {
  margin: 1rem auto;
}

.button-appointment {
  margin-left: 5rem;
  width: 32rem;

  h1 {
    font-size: 4.75rem;
    text-align: left;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: -1rem;
  }
}

.btn {
  background-color: $red;
  border: 0;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  margin: 12px 0;
  padding: 0.5rem 2.25rem;

  &:focus {
    outline-width: 0;
  }
}

.icon {
  width: 25rem;
}

@media (max-width: 60rem) {
  .icon {
    width: 15rem;
  }

  .button-appointment {
    margin-left: 0;
    width: auto;

    h1 {
      font-size: 2rem;
    }
  }
}
</style>
