<template>
  <div id="how-it-works">
    <h1 class="title">{{ $t('howItWorks.title') }}</h1>
    <div class="flex justify-space-between align-center wrap-sm">
      <div class="flex column align-center">
        <PaymentStep
          @goToStep="goToStep(1)"
          :is-active="step === 1"
          :step="1"
        />
        <div class="separator" />
        <PaymentStep
          @goToStep="goToStep(2)"
          :is-active="step === 2"
          :step="2"
        />
        <div class="separator" />
        <PaymentStep
          @goToStep="goToStep(3)"
          :is-active="step === 3"
          :step="3"
        />
        <div class="separator" />
        <PaymentStep
          @goToStep="goToStep(4)"
          :is-active="step === 4"
          :step="4"
        />
        <div class="separator" />
        <PaymentStep
          @goToStep="goToStep(5)"
          :is-active="step === 5"
          :step="5"
        />
      </div>
      <HowItWorksStep
        v-if="step === 1"
        @nextStep="nextStep"
        @previousStep="previousStep"
        :step="1"
        :title="$t('howItWorks.step1.title')"
        :descriptions="$t('howItWorks.step1.descriptions')"
      />
      <HowItWorksStep
        v-if="step === 2"
        @nextStep="nextStep"
        @previousStep="previousStep"
        :step="2"
        :title="$t('howItWorks.step2.title')"
      />
      <HowItWorksStep
        v-if="step === 3"
        @nextStep="nextStep"
        @previousStep="previousStep"
        :step="3"
        :title="$t('howItWorks.step3.title')"
      />
      <HowItWorksStep
        v-if="step === 4"
        @nextStep="nextStep"
        @previousStep="previousStep"
        :step="4"
        :title="$t('howItWorks.step4.title')"
        :descriptions="$t('howItWorks.step4.descriptions')"
      />
      <HowItWorksStep
        v-if="step === 5"
        @nextStep="nextStep"
        @previousStep="previousStep"
        :step="5"
        :title="$t('howItWorks.step5.title')"
        :descriptions="$t('howItWorks.step5.descriptions')"
      />
      <img
        :src="imageSrc()"
        class="illustration"
      />
    </div>
  </div>
</template>

<script>
import PaymentStep from '@/components/PaymentStep'
import HowItWorksStep from '@/components/HowItWorksStep'

export default {
  name: 'HowItWorks',
  components: {
    PaymentStep,
    HowItWorksStep
  },
  props: {
    image: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      step: 1
    }
  },
  methods: {
    nextStep() {
      this.step++
    },
    previousStep() {
      this.step--
    },
    goToStep(step) {
      this.step = step
    },
    imageSrc() {
      return require(`@/assets/images/${this.image}.svg`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#how-it-works {
  margin: 0 auto;
  max-width: 65rem;
}

.title {
  font-size: 3rem;
}

.steps {
  text-align: center;
}

.separator {
  background-color: $black;
  height: 3rem;
  width: 2px;
}

.illustration {
  width: 25rem;
}

@media (max-width: 90rem) {
  #how-it-works {
    max-width: 55rem;
  }
}

@media (max-width: 60rem) {
  #how-it-works {
    max-width: 40rem;
  }

  .title {
    font-size: 2rem;
  }

  .illustration {
    width: 15rem;
  }
}

@media (max-width: 30rem) {
  #how-it-works {
    margin: 1rem;
  }

  .illustration {
    margin: auto;
  }
}
</style>
