<template>
  <div id="pricing">
    <ProductsList />
    <CompanyInfo />
    <HowItWorks image="how-it-works"/>
    <WorkWithUs />
  </div>
</template>

<script>
import ProductsList from '@/components/ProductsList'
import CompanyInfo from '@/components/CompanyInfo'
import HowItWorks from '@/components/HowItWorks'
import WorkWithUs from '@/components/WorkWithUs'

export default {
  name: 'InfoPlan',
  components: {
    ProductsList,
    CompanyInfo,
    HowItWorks,
    WorkWithUs
  }
}
</script>
