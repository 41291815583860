<template>
  <div id="client-image">
    <img :src="iconId" class="icon" />
  </div>
</template>

<script>
export default {
  name: 'ClientImage',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    iconId: function() {
      return require(`@/assets/images/${this.icon}.svg`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#client-image {
  width: 25%;
}

.icon {
  height: 4rem;
  margin: 1rem;
}

@media (max-width: 30rem) {
  #client-image {
    width: 50%;
  }
}
</style>
