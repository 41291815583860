<template>
  <div id="about-us" class="flex column align-center">
    <h1>{{ $t('home.aboutUs.title') }}</h1>
    <p class="text-about-us">{{ $t('home.aboutUs.text') }}</p>
    <button class="btn" @click.prevent="$router.push({name: 'AboutUs'})">{{ $t('home.aboutUs.button') }}</button>
    <hr class="divider" />
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#about-us {
  margin: 1rem auto;

  h1 {
    font-size: 3rem;
  }

  .text-about-us {
    text-align: left;
    width: 60rem;
  }
}

.btn {
  background-color: $red;
  border: 0;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  margin: 12px 0;
  padding: 0.5rem 2.25rem;

  &:focus {
    outline-width: 0;
  }
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
  margin-block-end: 0.5rem;
  margin-block-start: 2rem;
  width: 45%;
}

@media (max-width: 60rem) {
  #about-us {
    h1 {
      font-size: 2rem;
    }

    .text-about-us {
      margin: 0 1.5rem;
      width: auto;
    }
  }
}

@media (max-width: 30rem) {
  .btn {
    margin-top: 3rem;
  }
}
</style>
