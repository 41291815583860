<template>
  <div id="contact" class="flex align-center justify-center column-m">
    <div class="description">
      <img src="@/assets/images/logo_wardenclyffe.svg" class="logo" />
      <hr class="separator" />
      <h1 class="title">{{ $t('contact.title') }}</h1>
      <p class="text">{{ $t('contact.text') }}</p>
    </div>
    <div class="calendly-inline-widget" :data-url="calendlyDataUrl" />
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data() {
    return {
      calendlyUser: process.env.VUE_APP_CALENDLY_USER
    }
  },
  computed: {
    calendlyDataUrl: function() {
      return `https://calendly.com/${this.calendlyUser}/15min?hide_event_type_details=1&hide_gdpr_banner=1`
    }
  },
  mounted() {
    const scriptTag = document.createElement('script')
    scriptTag.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(scriptTag)
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#contact {
  margin: 4rem 5rem;
  min-height: 60vh;
}

.logo {
  width: 18rem;
}

.description {
  border-right: 1px solid $black;
  width: 25rem;
}

.separator {
  border-top: 1px solid $black;
  margin-top: 2rem;
}

.title {
  color: $green;
  font-size: 2.5rem;
  text-align: left;
}

.text {
  font-size: 1rem;
  line-height: 1.75rem;
  padding-right: 1rem;
  text-align: left;
}

.calendly-inline-widget {
  height: 40rem;
  width: 40rem;
}

@media (max-width: 60rem) {
  #contact {
    margin: auto;
  }

  .description {
    border-right: 0;
    margin: 0 2rem;
    width: auto;
  }

  .logo {
    width: 12rem;
  }

  .title {
    text-align: center;
  }

  .text {
    padding: 0;
  }

  .calendly-inline-widget {
    height: 35rem;
    margin: 0 1rem;
  }
}

@media (max-width: 30rem) {
  .calendly-inline-widget {
    height: 30rem;
    margin: 0;
    width: auto;
  }
}
</style>
