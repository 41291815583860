import { getStateCommissions } from '@/api/stateCommissions'

const getDefaultState = () => {
  return {
    all: []
  }
}

const state = getDefaultState()

const mutations = {
  UPDATE_STATE_COMMISSIONS: (state, value) => {
    state.all = value
  }
}

const actions = {
  setStateCommissions({ commit }) {
    getStateCommissions().then(response => {
      commit('UPDATE_STATE_COMMISSIONS', response.data)
    }).catch(error => {
      throw error
    })
  }
}

const getters = {
  stateCommissions: state => {
    return state.all
  },
  selectedStateCommission: (state, getters, rootState) => {
    return state.all.find(stateCommission => stateCommission.id === rootState.order.stateCommissionId)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
