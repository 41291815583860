<template>
  <div id="types-of-companies">
    <h1>{{ $t('home.companyTypes.title') }}</h1>
    <section class="flex justify-space-evenly row wrap column-m">
      <TypesOfCompaniesIcon icon="corporation" :title="$t('home.companyTypes.corporation.title')" :subtitle="$t('home.companyTypes.corporation.text')"/>
      <TypesOfCompaniesIcon icon="liability" :title="$t('home.companyTypes.limitedLiability.title')" :subtitle="$t('home.companyTypes.limitedLiability.text')"/>
      <TypesOfCompaniesIcon icon="non-profit" :title="$t('home.companyTypes.nonProfit.title')" :subtitle="$t('home.companyTypes.nonProfit.text')"/>
      <TypesOfCompaniesIcon icon="professional-corporation" :title="$t('home.companyTypes.professionalCorporation.title')" :subtitle="$t('home.companyTypes.professionalCorporation.text')"/>
    </section>
    <hr class="divider"/>
  </div>
</template>

<script>
import TypesOfCompaniesIcon from '@/components/Home/TypesOfCompaniesIcon'

export default {
  name: 'TypesOfCompanies',
  components: {
    TypesOfCompaniesIcon
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#types-of-companies {
  margin: 2rem auto;
  width: 80%;

  h1 {
    font-size: 3rem;
  }
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
  margin-block-end: 0.5rem;
  margin-block-start: 2rem;
  width: 35%;
}

@media (max-width: 60rem) {
  #types-of-companies {
    h1 {
      font-size: 2rem;
    }
  }
}

</style>
