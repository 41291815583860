<template>
  <div id="customer-opinions">
    <h1 class="title">¿Qué opinan nuestros clientes?</h1>
    <splide :options="splideOptions" class="default">
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
    </splide>
    <splide :options="splideMobileOptions" class="mobile">
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
      <splide-slide>
        <CustomerOpinionsCard />
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import CustomerOpinionsCard from '@/components/Home/CustomerOpinionsCard'

export default {
  name: 'CustomerOpinion',
  components: {
    CustomerOpinionsCard,
    Splide,
    SplideSlide
  },
  data() {
    return {
      splideOptions: {
        autoplay: true,
        gap: '2rem',
        padding: {
          right: '15rem',
          left: '15rem'
        },
        pagination: false,
        type: 'loop'
      },
      splideMobileOptions: {
        autoplay: true,
        gap: '2rem',
        pagination: false,
        type: 'loop'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

.title {
  font-size: 3rem;
}

.carousel {
  overflow: hidden;
  width: 100%;
}

.mobile {
  display: none;
}

@media (max-width: 60rem) {
  .title {
    font-size: 2rem;
  }
}

@media (max-width: 30rem) {
  .default {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>
