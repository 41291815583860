<template>
  <form id="company">
    <h1>{{ $t('payForm.titles.company') }}</h1>
    <div class="flex align-center column-sm align-stretch-sm">
      <label class="input-label">{{ $t('payForm.companyName') }}</label>
      <div class="grow-1">
        <input
          type="text"
          name="company-name"
          class="text-input"
          v-model="companyName"
          autofocus
          required
        />
        <div v-if="showErrors && companyNameErrors.length" class="error-msg">{{ companyNameErrors.join(' - ') }}</div>
      </div>
    </div>
    <div class="flex align-center column-sm align-stretch-sm">
      <label class="input-label">{{ $t('payForm.email') }}</label>
      <div class="grow-1">
        <input
          type="email"
          name="email"
          class="text-input"
          v-model="userEmail"
          required
        />
        <div v-if="showErrors && userEmailErrors.length" class="error-msg">{{ userEmailErrors.join(' - ') }}</div>
      </div>
    </div>
    <div class="flex align-center column-sm align-stretch-sm">
      <label class="input-label">{{ $t('payForm.name') }}</label>
      <div class="grow-1">
        <input
          type="text"
          name="user-name"
          class="text-input"
          v-model="userName"
          required
        />
        <div v-if="showErrors && userNameErrors.length" class="error-msg">{{ userNameErrors.join(' - ') }}</div>
      </div>
    </div>
    <div class="flex align-center column-sm align-stretch-sm">
      <label class="input-label">{{ $t('payForm.phone') }}</label>
      <div class="grow-1">
        <input
          type="text"
          name="phone"
          class="text-input"
          v-model="userPhone"
          required
        />
        <div v-if="showErrors && userPhoneErrors.length" class="error-msg">{{ userPhoneErrors.join(' - ') }}</div>
      </div>
    </div>
    <div class="flex align-center column-sm align-stretch-sm">
      <label class="input-label">{{ $t('payForm.address') }}</label>
      <div class="grow-1">
        <input
          type="text"
          name="address"
          class="text-input"
          v-model="userAddress"
          required
        />
        <div v-if="showErrors && userAddressErrors.length" class="error-msg">{{ userAddressErrors.join(' - ') }}</div>
      </div>
    </div>
    <div class="flex justify-space-between column-sm">
      <div class="flex align-baseline short-input-group">
        <label class="input-label">{{ $t('payForm.country') }}</label>
        <div class="grow-1">
          <select v-model="userCountry" class="text-input select">
            <option value="US">{{ $t('unitedStates') }}</option>
            <option value="MX">{{ $t('mexico') }}</option>
          </select>
          <div v-if="showErrors && userCountryErrors.length" class="error-msg">{{ userCountryErrors.join(' - ') }}</div>
        </div>
      </div>
      <div class="flex align-baseline short-input-group">
        <label class="input-label">{{ $t('payForm.zipCode') }}</label>
        <div class="grow-1">
          <input
            type="text"
            name="zipcode"
            class="text-input grow-1"
            v-model="userZipcode"
            required
          />
          <div v-if="showErrors && userZipcodeErrors.length" class="error-msg">{{ userZipcodeErrors.join(' - ') }}</div>
        </div>
      </div>
    </div>
    <div class="flex justify-flex-end">
      <button class="btn black" @click.prevent="nextStep">{{ $t('payForm.next') }}</button>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Company',
  data() {
    return {
      showErrors: false
    }
  },
  computed: {
    ...mapState('order', [
      'productId',
      'stateCommissionId'
    ]),
    ...mapGetters('order', [
      'companyNameErrors',
      'userEmailErrors',
      'userNameErrors',
      'userPhoneErrors',
      'userAddressErrors',
      'userCountryErrors',
      'userZipcodeErrors',
      'isCompanyStepValid'
    ]),
    companyName: {
      get() { return this.$store.state.order.companyName },
      set(value) { this.updateCompanyName(value) }
    },
    userEmail: {
      get() { return this.$store.state.order.userEmail },
      set(value) { this.updateUserEmail(value) }
    },
    userName: {
      get() { return this.$store.state.order.userName },
      set(value) { this.updateUserName(value) }
    },
    userPhone: {
      get() { return this.$store.state.order.userPhone },
      set(value) { this.updateUserPhone(value) }
    },
    userAddress: {
      get() { return this.$store.state.order.userAddress },
      set(value) { this.updateUserAddress(value) }
    },
    userCountry: {
      get() { return this.$store.state.order.userCountry },
      set(value) { this.updateUserCountry(value) }
    },
    userZipcode: {
      get() { return this.$store.state.order.userZipcode },
      set(value) { this.updateUserZipcode(value) }
    }
  },
  methods: {
    ...mapActions('order', [
      'updateCompanyName',
      'updateUserEmail',
      'updateUserName',
      'updateUserPhone',
      'updateUserAddress',
      'updateUserCountry',
      'updateUserZipcode',
      'trimCompanyInfo'
    ]),
    nextStep() {
      this.showErrors = true

      if (this.productId && this.stateCommissionId && this.isCompanyStepValid) {
        this.trimCompanyInfo()
        this.$emit('nextStep')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#company {
  text-align: left;
}

.input-label {
  max-width: 10rem;
  min-width: 10rem;
  text-align: left;
}

.text-input {
  background-color: $light-grey;
  border-width: 0;
  box-sizing: border-box;
  font-size: 1rem;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;

  &:focus {
    outline-width: 0;
  }
}

.error-msg {
  color: $red;
  font-size: 0.75rem;
  margin-top: -1rem;
}

.short-input-group {
  width: 48%;
}

.btn {
  border: 0;
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0.75rem 0;
  padding: 0.75rem 1.5rem;

  &:focus {
    outline-width: 0;
  }

  &.cancel {
    background-color: #ffffff;
    color: $black;
  }

  &.white {
    background-color: #ffffff;
    border: 1px solid $grey;
    color: $black;
    margin: 0 2rem;
  }

  &.black {
    background-color: $black;
    border: 1px solid $black;
    color: $white;
  }
}

@media (max-width: 30rem) {
  .input-label {
    max-width: 8rem;
    min-width: 8rem;
  }

  .short-input-group {
    width: auto;
  }

  .text-input {
    margin: 0.25rem 0 2rem;
  }

  .error-msg {
    margin-bottom: 1.5rem;
    margin-top: -1.75rem;
  }
}
</style>
