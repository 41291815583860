<template>
  <div id="corporative-organization">
    <div class="header flex justify-center column-m align-center-m">
      <img src="@/assets/images/logo_wardenclyffe.svg" class="logo" />
      <h1 class="title">{{ $t('aboutUs.organizationStructure') }}</h1>
    </div>
    <img src="@/assets/images/corporative_org.png" class="diagram" />
  </div>
</template>

<script>
export default {
  name: 'CorporativeOrganization'
}
</script>

<style scoped lang="scss">
#corporative-organization {
  margin-top: 5rem;
}

.header {
  margin: 0 10rem;
}

.logo {
  width: 15rem;
}

.title {
  font-size: 2.75rem;
  margin-left: 3rem;
  text-align: left;
}

.diagram {
  margin-top: 3rem;
  max-width: 70rem;
  width: 75%;
}

@media (max-width: 90rem) {
  .header {
    margin: 0 8rem;
  }

  .title {
    margin-left: 4rem;
  }

  .logo {
    width: 13rem;
  }
}

@media (max-width: 60rem) {
  .header {
    margin: auto;
    width: 90%;
  }

  .logo {
    width: 12rem;
  }

  .title {
    font-size: 1.75rem;
    margin: 1rem 0 0;
    text-align: center;
  }

  .diagram {
    width: 85%;
  }
}
</style>
