export default [
  {
    name: 'California',
    color: '#CD0F27',
    sections: [
      {
        name: 'Corporation',
        points: [
          'Legislation: Corporations Code.',
          'Clasification: Business Corporations',
          'Minimum number of partners or shareholders: 1.',
          'No restrictions apply.',
          'Social Capital: Subscribed, but not paid. No minimum amount to subscribe. Established in the statutes.',
          'Liability limited to the company.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Different types of actions.',
          'Organizational structure: A single administrator or board of directors.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority shareholder with voting rights, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - Sales Tax mensual, quarterly prepaid, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual. - Franchise Tax.'
        ]
      },
      {
        name: 'Limited Liability Corporation',
        points: [
          'Legislation: Corporations Code (California revised uniform llc act).',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'No restrictions apply.',
          'Social Capital: - No need to subscribe. - No minimum amount in case you subscribe.',
          'Liability limited to the company.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Actions: "Member units" and exist different types of members.',
          'Organizational structure: - Admin partners; Administrators who are not partners; or directors board.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority of members, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - Sales Tax mensual, quarterly prepaid, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual. - Franchise Tax.'
        ]
      },
      {
        name: 'Non-profit Corporation',
        points: [
          'Legislation: Corporations Code (Nonprofit corporation law).',
          'Clasification: Non-profit corporation.',
          'Minimum number of partners or shareholders: There are no partners or shareholders.',
          'Restrictions: The name of the organization may not include the words "bank", "trust" or similar without the approval of the commissioner of financial institutions.',
          'Social Capital: No subscription.',
          'No personal liability to directors and executives. Damages will be covered in accordance with an insurance policy.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'No actions.',
          'Organizational structure: Directors board.',
          'Constitution: Processing and filing of articles of incorporation at the secretary of state.',
          'Quorum: One third of the votes entitled to be cast, unless otherwise stated in the statutes.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - No income o Sales Tax.'
        ]
      },
      {
        name: 'Professional Corporation',
        points: [
          'Legislation: Corporations Code.',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'Restrictions: All partners must exercise the same profession and according to the services offered by the organization.',
          'Social Capital: - Subscribed, but not paid. No minimum amount to subscribe. Established in the statutes.',
          'Personal responsibility.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Actions: Different types with restriction on their transfer.',
          'Organizational structure: - A single administrator or directors board.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority shareholding, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: Sales Tax mensual, quarterly prepaid, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual.'
        ]
      }
    ]
  },
  {
    name: 'New York',
    color: '#258135',
    sections: [
      {
        name: 'Corporation',
        points: [
          'Legislation: Business Corporation Law.',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'No restrictions apply.',
          'Social Capital: Subscribed, but not paid. No minimum amount to subscribe. Established in the statutes.',
          'Liability limited to company assets.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Different types of actions.',
          'Organizational structure: A single administrator or board of directors.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority of shares with voting rights, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - Sales Tax mensual, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual. - Franchise Tax.'
        ]
      },
      {
        name: 'Limited Liability Corporation',
        points: [
          'Legislation: Limited Liability Company Law.',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'No restrictions apply.',
          'Social Capital: - No need to subscribe. - no minimum amount in case you subscribe.',
          'Liability limited to company assets.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Actions: No actions, members ranking.',
          'Organizational structure: - Admin partners; Administrators who are not partners; or directors board.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority of members with voting rights, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - Sales Tax mensual, quarterly prepaid, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual. - Franchise Tax.'
        ]
      },
      {
        name: 'Non-profit Corporation',
        points: [
          'Legislation: Not-For-Profit Corporation Law.',
          'Clasification: Non-profit corporation.',
          'Minimum number of partners or shareholders: 1.',
          'Restrictions: The name of the organization may not include the words "bank", "trust" or similar without the approval of the commissioner of financial institutions.',
          'Social Capital: No subscription.',
          'No personal liability to directors and executives.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'No actions.',
          'Organizational structure: Directors board.',
          'Constitution: Processing and filing of articles of incorporation at the secretary of state.',
          'Quorum: Majority of the total number of votes entitled to be cast, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - No income o Sales Tax.'
        ]
      },
      {
        name: 'Professional Corporation',
        points: [
          'Legislation: Business Corporation Law.',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'Restrictions: All partners must exercise the same profession and according to the services offered by the organization.',
          'Social Capital: - Subscribed, but not paid. No minimum amount to subscribe. Established in the statutes.',
          'Personal responsibility.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Actions: Different types with restriction on their transfer.',
          'Organizational structure: - A single administrator or directors board.',
          'Constitution: Processing of the certificate of incorporation to the state department.',
          'Quorum: Majority of shares with voting rights, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: Sales Tax mensual, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual.'
        ]
      }
    ]
  },
  {
    name: 'Texas',
    color: '#EBC01B',
    sections: [
      {
        name: 'Corporation',
        points: [
          'Legislation: Business Organization Code.',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'No restrictions apply.',
          'Social Capital: Subscribed, but not paid. No minimum amount to subscribe. Established in the statutes.',
          'Liability limited to company assets.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Different types of actions.',
          'Organizational structure: A single administrator or board of directors.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority shareholder with voting rights.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - Sales Tax mensual, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual. - Franchise Tax.'
        ]
      },
      {
        name: 'Limited Liability Corporation',
        points: [
          'Legislation: Business Organization Code.',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'No restrictions apply.',
          'Social Capital: - No need to subscribe. - no minimum amount in case you subscribe.',
          'Liability limited to company assets.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Actions: No actions, clasificación de miembros.',
          'Organizational structure: - Admin partners; Administrators who are not partners; or directors board.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority of members, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - Sales Tax mensual, quarterly prepaid, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual. - Franchise Tax.'
        ]
      },
      {
        name: 'Non-profit Corporation',
        points: [
          'Legislation: Corporations Code (Nonprofit corporation law).',
          'Clasification Private corporation or public charity.',
          'Minimum number of partners or shareholders: There are no partners or shareholders.',
          'Restrictions: Dividends are not paid.',
          'Social Capital: No subscription.',
          'No personal liability to directors and executives. Damages will be covered in accordance with an insurance policy.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'No actions.',
          'Organizational structure: Directors board.',
          'Constitution: Processing and filing of articles of incorporation at the secretary of state.',
          'Quorum: One tenth of the votes entitled to be cast, unless otherwise stated in the training certificate or in the statutes.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: - No income o Sales Tax.'
        ]
      },
      {
        name: 'Professional Corporation',
        points: [
          'Legislation: Corporations Code.',
          'Clasification: Business Corporation.',
          'Minimum number of partners or shareholders: 1.',
          'Restrictions: All partners must exercise the same profession and according to the services offered by the organization.',
          'Social Capital: - Subscribed, but not paid. No minimum amount to subscribe. Established in the statutes.',
          'Each partner is responsible for their negligence and professional liability.',
          'Any object that is legal.',
          'Definite or indefinite lifetime.',
          'Actions: Different types with restriction on their transfer.',
          'Organizational structure: - A single administrator or directors board.',
          'Constitution: Processing of the training certificate at the secretary of state.',
          'Quorum: Majority shareholding, unless otherwise agreed.',
          'Modification of statutes: Decision of the partners.',
          'Applicable taxes: Sales Tax mensual, quarterly or yearly, in a natural year or fiscal year. - Income Tax anual.'
        ]
      }
    ]
  }
]
