<template>
  <div id="questions" class="flex column align-center">
    <h1>{{ $t('home.faq.title') }}</h1>
    <div class="card">
      <div v-for="(faq, index) in $t('home.faq.all')" :key="index"  class="card-content">
        <h3>{{ faq.title }}</h3>
        <p>{{ faq.answer }}</p>
      </div>
    </div>
    <hr class="divider" />
  </div>
</template>

<script>
export default {
  name: 'Questions'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#questions {
  margin: 1rem auto;

  h1 {
    font-size: 3rem;
  }
}

.card {
  width: 60rem;

  .card-content {
    text-align: start;
  }
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
  width: 50%;
}

@media (max-width: 60rem) {
  #questions {
    h1 {
      font-size: 2rem;
    }
  }

  .card {
    margin: 0 1.5rem;
    width: auto;
  }
}
</style>
