<template>
  <div id="requirements">
    <h1>{{ $t('home.requirements.title') }}</h1>
    <section class="requirements-info">
      <h3><i class="triangle-up green"></i>{{ $t('home.requirements.all[0]') }}</h3>
      <h3><i class="triangle-up"></i>{{ $t('home.requirements.all[1]') }}</h3>
      <h3><i class="triangle-up yellow"></i>{{ $t('home.requirements.all[2]') }}</h3>
      <h3><i class="triangle-up blue"></i>{{ $t('home.requirements.all[3]') }}</h3>
    </section>
    <hr class="divider" />
  </div>
</template>

<script>
export default {
  name: 'Requirements'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#requirements {
  margin: 1rem auto;

  h1 {
    font-size: 3rem;
  }
}

.requirements-info {
  font-size: 1.25rem;
  margin: 0 auto;
  text-align: left;
  width: 45%;

  h3 {
    margin-bottom: 0.5rem;
  }
}

.triangle-up {
  border-bottom: 7px solid transparent;
  border-left: 12px solid $red;
  border-top: 6px solid transparent;
  height: 0;
  margin-left: -1.5rem;
  margin-top: 0.75rem;
  position: absolute;
  width: 0;
}

.green {
  border-left: 12px solid $green;
}

.yellow {
  border-left: 12px solid $yellow;
}

.blue {
  border-left: 12px solid $blue;
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
  margin-block-end: 0.5rem;
  margin-block-start: 2rem;
  width: 45%;
}

@media (max-width: 60rem) {
  #requirements {
    h1 {
      font-size: 2rem;
    }
  }
}

@media (max-width: 30rem) {
  #requirements {
    margin: 0 1rem;

    h1 {
      font-size: 2rem;
    }
  }

  .requirements-info {
    width: 75%;
  }
}
</style>
