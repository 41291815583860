<template>
  <div id="business-organization">
    <div class="states-container flex justify-flex-end justify-center-sm">
      <img @click="changeSelectedState('California')" src="@/assets/images/california.svg" class="state-icon">
      <img @click="changeSelectedState('Texas')" src="@/assets/images/texas.svg" class="state-icon">
      <img @click="changeSelectedState($t('newYork'))" src="@/assets/images/new_york.svg" class="state-icon">
    </div>
    <h1 class="title">{{ $t('businessOrganization.title', { state: selectedState }) }}</h1>
    <BusinessOrganizationSection
      v-for="(section, index) in stateData.sections"
      :key="index"
      :color="stateData.color"
      :title="section.name"
      :points="section.points"
    />
  </div>
</template>

<script>
import StatesDataEn from '@/utils/business_org_data-en'
import StatesDataEs from '@/utils/business_org_data-es'
import BusinessOrganizationSection from '@/components/BusinessOrganizationSection'

export default {
  name: 'BusinessOrganization',
  components: {
    BusinessOrganizationSection
  },
  data() {
    return {
      selectedState: 'Texas'
    }
  },
  computed: {
    stateData: function() {
      const locale = this.$i18n.locale

      if (locale === 'en') {
        return StatesDataEn.find(state => state.name === this.selectedState)
      }
      else {
        return StatesDataEs.find(state => state.name === this.selectedState)
      }
    }
  },
  methods: {
    changeSelectedState: function(stateName) {
      this.selectedState = stateName
    }
  }
}
</script>

<style scoped lang="scss">
#business-organization {
  margin-top: 3rem;
}

.title {
  font-size: 3rem;
  margin: auto 5rem;
  text-align: left;
  width: 35rem;
}

.states-container {
  margin: 0 5rem;
}

.state-icon {
  cursor: pointer;
  height: 6rem;
  margin: 0 1rem;
}

@media (max-width: 60rem) {
  .title {
    margin: auto 3rem;
  }

  .states-container {
    margin: 0 3rem;
  }

  .state-icon {
    height: 5rem;
  }
}

@media (max-width: 30rem) {
  .title {
    font-size: 1.75rem;
    margin: 2rem 1.25rem;
    width: auto;
  }
}
</style>
