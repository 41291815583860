<template>
  <div id="company-history-event" class="flex">
    <div class="flex column align-center">
      <div class="separator" />
      <div class="indicator" />
      <div class="separator" />
    </div>
    <div class="information">
      <h3 class="date">{{ date }}</h3>
      <p>{{ event }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyHistoryEvent',
  props: {
    date: String,
    event: String
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#company-history-event {
  text-align: left;

  .separator {
    background-color: $black;
    height: calc((100% - 30px) / 2);
    width: 1px;
  }

  &:nth-child(1) {
    .separator:nth-child(1) {
      background-color: #ffffff;
    }
  }

  &:nth-last-child(1) {
    .separator:nth-last-child(1) {
      background-color: #ffffff;
    }
  }
}

.indicator {
  background-color: $black;
  border-radius: 30px;
  height: 30px;
  width: 30px;
}

.information {
  margin-left: 2rem;
  width: 30rem;
}

@media (max-width: 60rem) {
  .information {
    width: 25rem;
  }
}

@media (max-width: 30rem) {
  .date {
    margin: 0.25rem 0;
  }

  .separator {
    height: calc((100% - 20px) / 2);
  }

  .indicator {
    background-color: $black;
    border-radius: 20px;
    height: 20px;
    width: 20px;
  }

  .information {
    width: 15rem;
  }
}
</style>
