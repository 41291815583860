<template>
  <div id="card-form">
    <div id="card-element" />
    <div v-if="errorMessage.length > 0" class="card-error" id="card-errors" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardForm',
  props: {
    errorMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#card-form {
  background-color: $grey;
  margin-top: 2rem;
  padding: 1rem;
  width: 35%;
}

.card-error {
  color: $red;
  margin-top: 0.5rem;
}

@media (max-width: 60rem) {
  #card-form {
    width: 50%;
  }
}

@media (max-width: 30rem) {
  #card-form {
    width: auto;
  }
}
</style>
