<template>
  <div id="home">
    <BannerHome />
    <AppointmentLinkBanner :title="$t('home.contactBanner.titles[0]')" :subtitle="$t('home.contactBanner.subtitles[0]')" />
    <MediaVideo />
    <TypesOfCompanies />
    <OurServices />
    <ProductsList />
    <AppointmentLinkBanner :title="$t('home.contactBanner.titles[1]')" :subtitle="$t('home.contactBanner.subtitles[1]')" />
    <HowItWorks image="bolt" />
    <PodcastBanner />
    <Requirements />
    <AboutUs />
    <Questions />
    <AfterPayment />
    <Clients />
    <CustomerOpinions />
  </div>
</template>

<script>
import BannerHome from '@/components/Home/BannerHome'
import MediaVideo from '@/components/Home/MediaVideo'
import TypesOfCompanies from '@/components/Home/TypesOfCompanies'
import OurServices from '@/components/Home/OurServices'
import AppointmentLinkBanner from '@/components/Home/AppointmentLinkBanner'
import PodcastBanner from '@/components/Home/PodcastBanner'
import Requirements from '@/components/Home/Requirements'
import AboutUs from '@/components/Home/AboutUs'
import Questions from '@/components/Home/Questions'
import AfterPayment from '@/components/Home/AfterPayment'
import Clients from '@/components/Home/Clients'
import CustomerOpinions from '@/components/Home/CustomerOpinions'
import ProductsList from '@/components/ProductsList'
import HowItWorks from '@/components/HowItWorks'

export default {
  name: 'Home',
  data() {
    return {
    }
  },
  components: {
    HowItWorks,
    ProductsList,
    BannerHome,
    MediaVideo,
    TypesOfCompanies,
    OurServices,
    AppointmentLinkBanner,
    PodcastBanner,
    Requirements,
    AboutUs,
    Questions,
    AfterPayment,
    Clients,
    CustomerOpinions
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/global';

#home {
  width: 100%;
}

</style>
