<template>
  <div id="work-with-us">
    <h1 class="title">{{ $t('pricing.workWithUs.title') }}</h1>
    <WorkWithUsSlide
      icon="expertise"
      :title="$t('pricing.workWithUs.slides[0].title')"
      :content="$t('pricing.workWithUs.slides[0].text')"
    />
    <WorkWithUsSlide
      icon="dedication"
      :title="$t('pricing.workWithUs.slides[1].title')"
      :content="$t('pricing.workWithUs.slides[1].text')"
    />
    <WorkWithUsSlide
      icon="security"
      :title="$t('pricing.workWithUs.slides[2].title')"
      :content="$t('pricing.workWithUs.slides[2].text')"
    />
    <WorkWithUsSlide
      icon="efficiency"
      :title="$t('pricing.workWithUs.slides[3].title')"
      :content="$t('pricing.workWithUs.slides[3].text')"
    />
  </div>
</template>

<script>
import WorkWithUsSlide from '@/components/WorkWithUsSlide'

export default {
  name: 'WorkWithUs',
  components: {
    WorkWithUsSlide
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#work-with-us {
  margin: 0 auto;
  max-width: 85rem;
}

.title {
  font-size: 3rem;
}

@media (max-width: 30rem) {
  #work-with-us {
    margin: 4rem auto;
  }

  .title {
    font-size: 2rem;
  }
}
</style>
