<template>
  <div id="company-info">
    <hr class="separator" />
    <h1 class="title">{{ $t('companyInformationForm.title') }}</h1>
    <h1 class="subtitle">{{ $t('companyInformationForm.text') }}</h1>
    <CompanyTypeForm />
    <hr class="separator" />
  </div>
</template>

<script>
import CompanyTypeForm from '@/components/CompanyTypeForm'

export default {
  name: 'CompanyInfo',
  components: {
    CompanyTypeForm
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#company-info {
  margin: 6rem 2.5rem;
}

.separator {
  border: 1px solid $black;
  margin-block-end: 4rem;
  margin-block-start: 4rem;
  margin-inline-end: 20rem;
  margin-inline-start: 20rem;
}

.title {
  font-size: 3rem;
  margin-bottom: 3rem;
}

@media (max-width: 60rem) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.75rem;
  }

  .separator {
    border: 1px solid $black;
    margin-block-end: 4rem;
    margin-block-start: 4rem;
    margin-inline-end: 5rem;
    margin-inline-start: 5rem;
  }
}
</style>
