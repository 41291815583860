<template>
  <div id="company-history" class="flex justify-center column-reverse-sm align-center-sm">
    <div>
      <CompanyHistoryEvent
        v-for="(event, index) in $t('aboutUs.timeline')"
        :key="index"
        :date="event.date"
        :event="event.description"
      />
    </div>
    <img src="@/assets/images/hourglass.svg" class="icon" />
  </div>
</template>

<script>
import CompanyHistoryEvent from '@/components/CompanyHistoryEvent'

export default {
  name: 'CompanyHistory',
  components: {
    CompanyHistoryEvent
  }
}
</script>

<style scoped lang="scss">
#company-history {
  margin: 5rem 0;
}

.icon {
  margin-left: 5rem;
  width: 10rem;
}

@media (max-width: 60rem) {
  .icon {
    width: 7.5rem;
  }
}

@media (max-width: 30rem) {
  .icon {
    margin: 0 0 2rem;
    width: 6rem;
  }
}
</style>
