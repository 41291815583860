<template>
  <div id="types-of-companies-icon" class="flex row align-self-center justify-space-evenly column-sm">
    <div class="types-of-companies-icon flex row justify-center align-self-center">
      <img :src="iconId" class="icon" />
    </div>
    <div class="types-of-companies-info flex column justify-center align-self-center">
      <h4 class="title">{{ title }}<i class="triangle-up"></i></h4>
      <p class="subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypesOfCompaniesIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  },
  computed: {
    iconId: function() {
      return require(`@/assets/images/${this.icon}.svg`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#types-of-companies-icon {
  position: relative;
  width: 50%;
}

.types-of-companies-icon {
  margin: 0.2rem;
  width: 20%;
}

.types-of-companies-info {
  text-align: start;
  width: 50%;

  p {
    margin-top: -1.25rem;
  }
}

.icon {
  height: 5rem;
  width: 90%;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
}

.subtitle {
  font-size: 1rem;
  width: 100%;
}

.triangle-up {
  border-bottom: 7px solid transparent;
  border-left: 12px solid $red;
  border-top: 6px solid transparent;
  height: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
  position: absolute;
  width: 0;
}

.green {
  border-left: 12px solid $green;
}

.yellow {
  border-left: 12px solid $yellow;
}

.blue {
  border-left: 12px solid $blue;
}

@media (max-width: 60rem) {
  #types-of-companies-icon {
    width: auto;
  }
}

@media (max-width: 30rem) {
  .types-of-companies-info {
    width: auto;
  }
}
</style>
