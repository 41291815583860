<template>
  <div id="business-organization-section">
    <h1 class="title" :style="{ borderColor: color }">{{ title }}</h1>
    <ul class="list">
      <li v-for="(point, index) in points" :key="index">
        {{ point }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BusinessOrganizationSection',
  props: {
    color: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    points: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#business-organization-section {
  border: 1px solid $black;
  margin: 2rem 5rem;
  padding: 0 2rem;
  text-align: left;
}

.title {
  border-left: 1.25rem solid;
  font-size: 2.25rem;
  padding-left: 1.25rem;
}

@media (max-width: 60rem) {
  #business-organization-section {
    margin: 2rem 3rem;
  }
}

@media (max-width: 30rem) {
  #business-organization-section {
    margin: 2rem 1rem;
    padding: 0 1rem;
  }

  .title {
    border-left: 0.75rem solid;
    font-size: 1.25rem;
  }

  .list {
    padding-inline-start: 1.5rem;
  }
}
</style>
