<template>
  <div id="company-type-form" class="flex align-center justify-center column-m">
    <span class="text">{{ $t('selectedPlan') }}</span>
    <select v-model="productId" class="select-input">
      <option
        v-for="product in products"
        :key="product.id"
        :value="product.id"
      >
        {{ product[$i18n.locale].name }}
      </option>
    </select>
    <span class="text">{{ $t('planType') }}</span>
    <select v-model="companyType" class="select-input">
      <option :value="0">C Corporation</option>
      <option :value="1">S Corporation (US Residents Only)</option>
      <option :value="2">Limited Liabilty Company (LLC)</option>
      <option :value="3">Nonprofit</option>
    </select>
    <span class="text">{{ $t('in') }}</span>
    <select v-model="companyState" class="select-input">
      <option value="" disabled selected>{{ $t('placeholders.selectState') }}</option>
      <option
        v-for="stateCommission in stateCommissions"
        :key="stateCommission.id"
        :value="stateCommission.id"
      >
        {{ stateCommission[$i18n.locale].name }}
      </option>
    </select>
    <button v-if="showBtn" class="btn" @click.prevent="$router.push({name: 'Pay'})">{{ $t('accept') }}</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CompanyTypeForm',
  props: {
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('stateCommissions', ['stateCommissions']),
    ...mapGetters('products', ['products']),
    productId: {
      get() { return this.$store.state.order.productId },
      set(value) { this.updateProductId(value) }
    },
    companyType: {
      get() { return this.$store.state.order.companyType },
      set(value) { this.updateCompanyType(value) }
    },
    companyState: {
      get() { return this.$store.state.order.stateCommissionId },
      set(value) { this.updateStateCommissionId(value) }
    }
  },
  methods: {
    ...mapActions('order', [
      'updateProductId',
      'updateCompanyType',
      'updateStateCommissionId'
    ])
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

.text {
  margin: 1rem 2rem;
}

.select-input {
  cursor: pointer;
  padding: 0.5rem 2rem;
}

.btn {
  background-color: $red;
  border: 0;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 2rem;
  padding: 0.5rem 2.75rem;

  &:focus {
    outline-width: 0;
  }
}

@media (max-width: 60rem) {
  .select-input {
    padding: 0.5rem 1rem;
  }

  .btn {
    margin: 2rem;
  }
}
</style>
