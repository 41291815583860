<template>
  <div id="our-services">
    <h1>{{ $t('home.ourServices.title') }}</h1>
    <section class="flex row justify-space-evenly column-sm align-center-sm">
      <OurServiceIcon icon="Icono-Department-Sate" :title="$t('home.ourServices.stateDepartmentSubscription')" />
      <OurServiceIcon icon="Icono-EIN" :title="$t('home.ourServices.einObtaining')" />
      <OurServiceIcon icon="Icono-Asesoria-Juridica" :title="$t('home.ourServices.legalAdvisory')" />
      <OurServiceIcon icon="Icono-Asesoria-Contable" :title="$t('home.ourServices.accountantAdvisory')" />
    </section>
    <hr class="divider"/>
  </div>
</template>

<script>
import OurServiceIcon from '@/components/Home/OurServiceIcon'

export default {
  name: 'OurServices',
  components: {
    OurServiceIcon
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#our-services {
  margin: 2rem auto;

  h1 {
    font-size: 3rem;
  }
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
  margin-block-end: 0.5rem;
  margin-block-start: 2rem;
  width: 45%;
}

@media (max-width: 60rem) {
  #our-services {
    h1 {
      font-size: 2rem;
    }
  }
}

</style>
