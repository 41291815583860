import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import BusinessOrganization from '@/views/BusinessOrganization'
import Payment from '@/views/Payment'
import Pricing from '@/views/Pricing'
import Contact from '@/views/Contact'
import AboutUs from '@/views/AboutUs'
import Home from '@/views/Home'
import Order from '@/views/Order'
import Layout from '@/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: Layout,
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      const supportedLocales = ['en', 'es']

      if (!supportedLocales.includes(locale)) return next('en')
      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'business-organization',
        name: 'BusinessOrganization',
        component: BusinessOrganization
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: Pricing
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: AboutUs
      },
      {
        path: 'pay',
        name: 'Pay',
        component: Payment
      },
      {
        path: 'view_order/:userEmail/:token',
        name: 'ViewOrder',
        component: Order
      }
    ]
  },
  {
    path: '*',
    redirect: {
      path: '/en'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
