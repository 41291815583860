<template>
  <div id="work-with-us-slide" class="flex column-sm">
    <img :src="iconPath" class="icon" />
    <div class="content">
      <h2 class="title">{{ title }}</h2>
      <p class="text">{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkWithUsSlide',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    iconPath: function() {
      return require(`@/assets/images/${this.icon}.svg`)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#work-with-us-slide {
  margin: 0 7rem;
}

.icon {
  width: 5rem;
}

.img-content {
  width: 20%;
}

.content {
  margin-left: 2rem;
  text-align: start;
}

.title {
  margin-block-end: 0;
}

.text {
  font-size: 1.25rem;
  margin-block-start: 0;
}

@media (max-width: 30rem) {
  #work-with-us-slide {
    align-items: center;
    margin: 0 2rem;
  }
}
</style>
