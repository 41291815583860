<template>
  <div id="how-it-works-step">
    <h2>{{ $t('howItWorks.step') }} {{ step }}</h2>
    <h1 class="title">{{ title }}</h1>
    <div v-if="descriptions">
      <p
        v-for="(description, index) in descriptions"
        :key="index"
        class="description"
      >
        {{ description }}
      </p>
    </div>
    <div>
      <button v-if="step < 5" @click="$emit('nextStep')" class="btn">
        {{ $t('howItWorks.next') }}
      </button>
      <button v-if="step > 1" @click="$emit('previousStep')" class="btn white">
        {{ $t('howItWorks.previous') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorksStep',
  props: {
    step: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    descriptions: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#how-it-works-step {
  margin: 0 8rem 0 3rem;
  text-align: left;
}

.title {
  font-size: 2rem;
}

.description {
  font-size: 1.25rem;
}

.btn {
  background-color: $black;
  border: 0;
  border-radius: 0.75rem;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.75rem 0.25rem;
  padding: 0.75rem 1.5rem;

  &:focus {
    outline-width: 0;
  }

  &.white {
    background-color: $white;
    color: $black;
  }
}

@media (max-width: 60rem) {
  #how-it-works-step {
    margin: 0 4rem 0 3rem;
  }

  .title {
    font-size: 1.5rem;
  }
}

@media (max-width: 30rem) {
  #how-it-works-step {
    margin: 0;
    width: 75%;
  }
}
</style>
