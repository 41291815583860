const getDefaultState = () => {
  return {
    companyName: {
      required: true,
      minLength: 5
    },
    userEmail: [],
    userName: [],
    userPhone: [],
    userAddress: []
  }
}

const state = getDefaultState()

const mutations = {
  ADD_FIELD_ERROR: (state, { key, message }) => {
    state[key].push(message)
  },
  CLEAN_FIELD_ERRORS: (state, key) => {
    state[key] = []
  }
}

const actions = {
  validateCompanyName({ commit, rootState, state }) {
    if (!rootState.order.companyName) {
      commit('ADD_FIELD_ERROR', { key: 'companyName', message: 'Este campo no puede estar vacío' })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
