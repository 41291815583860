<template>
  <div id="media-video" class="flex align-center justify-center column-sm">
    <section>
      <img src="@/assets/images/Ilustracion-dos.svg" alt="enterate icon" class="icon" />
      <h1>{{ $t('home.media.subtitle') }}</h1>
    </section>
    <section>
      <h1>{{ $t('home.media.title') }}</h1>
      <video class="media-video-iframe" controls>
        <source src="@/assets/images/warden-video.mp4" type="video/mp4">
      </video>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MediaVideo'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#media-video {
  margin-top: 2rem;

  h1 {
    font-size: 2.5rem;
  }
}

.icon {
  margin-top: 10rem;
  width: 20rem;
}

.media-video-iframe {
  height: 450px;
  margin-left: 3rem;
  width: 800px;
}

@media (max-width: 60rem) {
  #media-video {
    margin: 0 1rem;

    h1 {
      font-size: 1.5rem;
    }
  }

  .icon {
    margin-top: 4rem;
    width: 12rem;
  }

  .media-video-iframe {
    height: 200px;
    margin-left: 0;
    width: 300px;
  }
}

@media (max-width: 30rem) {
  #media-video {
    h1 {
      font-size: 2rem;
    }
  }

  .icon {
    margin-top: 0;
    width: 10rem;
  }
}
</style>
