<template>
  <div id="clients" class="flex column align-center">
    <h1>{{ $t('home.clients') }}</h1>
    <section class="flex justify-space-evenly wrap">
      <ClientImage icon="analis-logo" />
      <ClientImage icon="esg-logo" />
      <ClientImage icon="lian-logo" />
      <ClientImage icon="lidea-logo" />
      <ClientImage icon="mamalolacorazon" />
      <ClientImage icon="musgx" />
      <ClientImage icon="ohqm-logo" />
      <ClientImage icon="smes-logo" />
      <ClientImage icon="winchester-logo" />
      <ClientImage icon="franco-logo" />
    </section>
  </div>
</template>

<script>
import ClientImage from '@/components/Home/ClientImage'

export default {
  name: 'Clients',
  components: {
    ClientImage
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#clients {
  margin: 1rem auto;

  h1 {
    font-size: 3rem;
  }
}

@media (max-width: 60rem) {
  #clients {
    h1 {
      font-size: 2rem;
    }
  }
}
</style>
