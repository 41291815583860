<template>
  <footer id="the-footer">
    <div class="flex column-sm">
      <ContactForm />
      <TheFooterScheduleLink />
      <TheFooterContactInformation />
    </div>
    <p class="text">&copy; WARDENCLYFFE, INC. ALL RIGHTS RESERVED - Privacy Policy | Terms &amp; Conditions | Cookies Policy</p>
  </footer>
</template>

<script>
import ContactForm from '@/components/ContactForm'
import TheFooterScheduleLink from '@/components/TheFooterScheduleLink'
import TheFooterContactInformation from '@/components/TheFooterContactInformation'

export default {
  name: 'TheFooter',
  components: {
    ContactForm,
    TheFooterScheduleLink,
    TheFooterContactInformation
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#the-footer {
  background-color: $grey;
  color: $black;
  margin-top: 5rem;
  padding-bottom: 1rem;
  text-align: center;
}

.text {
  font-size: 1.25rem;
}

@media (max-width: 30rem) {
  .text {
    font-size: 1rem;
    margin: 1.75rem 1rem;
  }
}
</style>
