<template>
  <div id="certifications">
    <h1 class="title">{{ $t('aboutUs.certifications') }}</h1>
    <img src="@/assets/images/dun_bradstreet.png" class="image" />
  </div>
</template>

<script>
export default {
  name: 'Certifications'
}
</script>

<style scoped lang="scss">
.title {
  font-size: 2.75rem;
}

.image {
  width: 30rem;
}

@media (max-width: 60rem) {
  .title {
    font-size: 1.7rem;
  }

  .image {
    width: 25rem;
  }
}

@media (max-width: 30rem) {
  .image {
    width: 15rem;
  }
}
</style>
