<template>
  <div id="products-list">
    <h1 class="title">{{ $t('productsList.title') }}</h1>
    <div class="products flex row justify-center justify-flex-start-sm">
      <ProductsListItem
        v-for="product in products"
        :key="product.id"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductsListItem from '@/components/ProductsListItem'

export default {
  name: 'ProductsList',
  components: {
    ProductsListItem
  },
  computed: {
    ...mapGetters('products', [
      'products'
    ])
  }
}
</script>

<style scoped lang="scss">
#products-list {
  margin: 0 2.5rem;
}

.title {
  font-size: 3rem;
  margin-bottom: 3rem;
}

@media (max-width: 60rem) {
  .title {
    font-size: 2rem;
  }
}

@media (max-width: 30rem) {
  #products-list {
    margin: 0;
  }

  .products {
    overflow-y: scroll;
    width: 100%;
  }
}
</style>
