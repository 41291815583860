<template>
  <div id="the-footer-schedule-link">
    <h2>{{ $t('footer.scheduleLink.title') }}</h2>
    <p class="text">{{ $t('footer.scheduleLink.text') }}</p>
    <button @click.prevent="$router.push({name: 'Contact'})" class="btn">
      {{ $t('footer.scheduleLink.button') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TheFooterScheduleLink'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#the-footer-schedule-link {
  margin: 1rem;
  text-align: center;
  width: calc(100% / 3);
}

.text {
  font-size: 1.5rem;
  height: 12rem;
  margin: auto;
  width: 20rem;
}

.btn {
  background-color: $red;
  border: 1px solid $red;
  border-radius: 6px;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  margin: 2rem 2rem 0;
  padding: 0.25rem 0.5rem;

  &:focus {
    outline: none;
  }
}

@media (max-width: 60rem) {
  #the-footer-schedule-link {
    margin: 0;
  }

  .text {
    width: auto;
  }
}

@media (max-width: 30rem) {
  #the-footer-schedule-link {
    margin: 1rem 0 0;
    width: 100%;
  }

  .text {
    height: auto;
    width: 20rem;
  }

  .btn {
    margin-top: 1.5rem;
  }
}
</style>
