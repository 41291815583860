<template>
  <div id="pay">
    <h1>{{ $t('payForm.titles.pay') }}</h1>
    <div class="flex justify-space-between column-sm">
      <div class="flex">
        <p>Plan:</p>
        <p class="value">{{ selectedProduct.formattedAmount }}</p>
      </div>
      <div class="flex">
        <p>{{ $t('payForm.monthlyFee') }}:</p>
        <p class="value">{{ selectedProduct.subscriptionFormattedAmount }}</p>
      </div>
      <div class="flex">
        <p>{{ $t('stateCommission') }}({{ selectedStateCommission[$i18n.locale].name }}):</p>
        <p class="value">{{ selectedStateCommission.formattedAmount }}</p>
      </div>
    </div>
    <div class="flex">
      <p>Total:</p>
      <p class="value">${{ subtotal }} + ({{ selectedProduct.subscriptionFormattedAmount }}/{{ $tc('month', 0) }} {{ $t('for') }} {{ $tc('month', selectedProduct.subscriptionMonths) }})</p>
    </div>
    <form>
      <div class="flex justify-flex-start checkbox border">
        <input
          type="radio"
          :value="0"
          v-model="paymentMethod"
        />
        <label for="paypal" class="label">{{ $t('payForm.payWith') }} {{ $t('payForm.creditCard') }}</label>
      </div>
      <CardForm
        :error-message="cardErrorMessage"
      />
      <div class="checkbox">
        <input
          @input="termsError = false"
          type="checkbox"
          id="terms-checkbox"
          v-model="termsAccepted"
        />
        <label for="terms-checkbox" class="label">{{ $t('payForm.acceptTerms') }}</label>
        <p v-if="termsError" class="terms-error">{{ $t('payForm.termsError') }}</p>
      </div>
    </form>
    <div class="flex justify-flex-end">
      <button class="btn white" @click.prevent="$emit('previousStep')">{{ $t('payForm.back') }}</button>
      <button :disabled="submitButtonDisabled" @click.prevent="submitForm" class="btn black">{{ $t('payForm.submit') }}</button>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import { mapActions, mapGetters } from 'vuex'
import CardForm from '@/components/CardForm'

export default {
  name: 'Pay',
  components: {
    CardForm
  },
  data() {
    return {
      paymentMethod: 0,
      termsAccepted: false,
      termsError: false,
      cardErrorMessage: '',
      submitButtonDisabled: false,
      stripe: undefined,
      card: undefined
    }
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)

    this.mountCardElement()
  },
  computed: {
    ...mapGetters('products', ['selectedProduct']),
    ...mapGetters('stateCommissions', ['selectedStateCommission']),
    subtotal() {
      return parseFloat(parseFloat(this.selectedProduct.amount) + parseFloat(this.selectedStateCommission.amount)).toFixed(2)
    },
    locale: function() {
      return this.$i18n.locale
    }
  },
  watch: {
    locale: function(newLocale, oldLocale) {
      this.mountCardElement()
    }
  },
  methods: {
    ...mapActions('order', ['createOrder', 'updateCardToken']),
    ...mapActions('app', [
      'openLoadingModal',
      'openSuccessModal',
      'openErrorModal',
      'closeLoadingModal',
      'changeSuccessOrderRedirectUrl'
    ]),
    mountCardElement() {
      const elements = this.stripe.elements({
        locale: this.$i18n.locale
      })

      this.card = elements.create('card', {
        iconStyle: 'solid',
        hidePostalCode: true,
        style: {
          base: {
            fontSize: '16px'
          }
        }
      })

      this.card.mount('#card-element')

      this.card.on('change', ({ error }) => {
        if (error) {
          this.cardErrorMessage = error.message
        }
        else {
          this.cardErrorMessage = ''
        }
      })
    },
    submitForm() {
      if (this.termsAccepted) {
        this.submitButtonDisabled = true
        this.termsError = false

        this.openLoadingModal()

        this.stripe.createToken(this.card).then(res => {
          if (res.error) {
            throw res.error
          }
          return res.token.id
        })
          .then(token => this.updateCardToken(token))
          .then(() => this.createOrder())
          .then(res => {
            this.changeSuccessOrderRedirectUrl(res.headers.location)
          })
          .then(() => {
            this.closeLoadingModal()
            this.openSuccessModal()
          })
          .catch(error => {
            this.submitButtonDisabled = false

            this.closeLoadingModal()
            this.openErrorModal()
            throw error
          })
      }
      else {
        this.termsError = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#pay {
  text-align: left;
}

.value {
  margin-left: 1rem;
}

.checkbox {
  margin-top: 3%;
  padding: 2%;

  &.border {
    border: 1px solid $black;
  }
}

.label {
  margin-left: 1rem;
}

.btn {
  border: 0;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 12px 0;
  padding: 0.6rem 1.5rem;

  &:focus {
    outline-width: 0;
  }

  &.cancel {
    background-color: #ffffff;
    color: $black;
  }

  &.white {
    background-color: #ffffff;
    border: 1px solid $grey;
    color: $black;
    margin: auto 2rem;
  }

  &.black {
    background-color: $black;
    border: 1px solid $black;
    color: $white;
  }
}

.terms-error {
  color: $red;
  margin: 0;
}
</style>
