<template>
  <div @click="$emit('goToStep')" id="payment-step" class="flex align-center">
    <div class="flex align-center justify-center number" :class="{ active: isActive}">
      {{ step }}
    </div>
    <div v-if="name" class="name">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentStep',
  props: {
    isActive: {
      type: Boolean
    },
    name: {
      type: String
    },
    step: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#payment-step {
  cursor: pointer;
}

.number {
  background-color: $grey;
  border-radius: 50px;
  color: #ffffff;
  font-size: 1.5rem;
  height: 3rem;
  width: 3rem;

  &.active {
    background-color: $black;
  }
}

.name {
  font-size: 1.25rem;
  margin-left: 1rem;
}
</style>
