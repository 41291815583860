<template>
  <header id="the-header" class="flex align-center justify-center">
    <svg @click.stop="openMobileSidebar" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="menu-icon dspl-none dspl-block-m" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
    <nav class="dspl-none-m">
      <router-link :to="routeUrl('/about-us')" class="link">
        {{ $t('header.aboutUs') }}
      </router-link>
      <router-link :to="routeUrl('/business-organization')" class="link">
        {{ $t('header.createYourCompany') }}
      </router-link>
      <router-link :to="routeUrl('/pricing')" class="link">
        {{ $t('header.plans') }}
      </router-link>
      <router-link :to="routeUrl('/contact')" class="link">
        {{ $t('header.contactUs') }}
      </router-link>
    </nav>
    <router-link :to="routeUrl('/')" class="flex align-center justify-center grow-1">
      <img src="@/assets/images/logo.svg" class="logo" />
    </router-link>
    <LocaleSelect />
    <nav class="social-links dspl-none-m">
      <a href="https://twitter.com/WardenclyffeUS" target="_blank" class="link">
        <img src="@/assets/images/twitter-logo-white.png" class="icon"/>
      </a>
      <a href="https://open.spotify.com/show/07jhwqvcfnZGdVPJCeTtAN?si=E_kaNKKeRFWu5s71EnCiCg" target="_blank" class="link">
        <img src="@/assets/images/spotify-logo-white.png" class="icon"/>
      </a>
      <a href="https://www.instagram.com/wardenclyffefirm" target="_blank" class="link">
        <img src="@/assets/images/instagram-logo-white.png" class="icon"/>
      </a>
      <a href="https://www.facebook.com/wardenclyffeinc" target="_blank" class="link">
        <img src="@/assets/images/facebook-logo-white.png" class="icon"/>
      </a>
      <a href="https://www.linkedin.com/company/65438634" target="_blank" class="link">
        <img src="@/assets/images/linkedin-logo-white.png" class="icon"/>
      </a>
    </nav>
  </header>
</template>

<script>
import LocaleSelect from '@/components/LocaleSelect'

export default {
  name: 'TheHeader',
  components: {
    LocaleSelect
  },
  methods: {
    routeUrl(url) {
      return `/${this.$i18n.locale}${url}`
    },
    openMobileSidebar() {
      const sidebar = document.getElementById('the-mobile-sidebar')

      sidebar.classList.add('animate-in')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#the-header {
  background-color: $black;
  color: $white;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  z-index: 4;
}

.link {
  color: $white;
  margin: 0.5rem;
  text-decoration: none;
}

.logo {
  margin-right: 8vw;
  width: 10rem;
}

.social-links {
  margin: 0 2rem;
}

.icon {
  height: 1.25rem;
}

@media (max-width: 60rem) {
  #the-header {
    margin-bottom: 2.5rem;
    padding: 1.75rem 1.5rem;
  }

  .menu-icon {
    width: 1.25rem;
  }
}
</style>
