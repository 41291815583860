<template>
  <div id="order" v-if="!loading">
    <h1 class="title">{{ $t('viewOrder.thanks') }}</h1>
    <div class="section">
      <h1>{{ $t('viewOrder.generalDetails') }}</h1>
      <div class="information-row"><span class="bold">{{ $t('payForm.companyName') }}:</span> {{ order.companyName }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.email') }}:</span> {{ order.company.email }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.name') }}:</span> {{ order.userName }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.phone') }}:</span> {{ order.userPhone }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.address') }}:</span> {{ order.userAddress }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.country') }}:</span> {{ order.userCountry }}</div>
      <div class="information-row"><span class="bold">{{ $t('payForm.zipCode') }}:</span> {{ order.userZipcode }}</div>
    </div>
    <h1>{{ $t('payForm.titles.companyDetails') }}</h1>
    <div class="section">
      <h2>{{ $t('payForm.titles.management') }}</h2>
      <div v-for="(manager, index) in order.company.managers" :key="index">
        <div class="information-row"><span class="bold">{{ $t('payForm.name') }}:</span> {{ manager.name }}</div>
        <div class="information-row"><span class="bold">{{ $t('payForm.address') }}:</span> {{ manager.address }}</div>
        <hr class="divider" />
      </div>
    </div>
    <div class="section">
      <h2>{{ $t('payForm.titles.capital') }}</h2>
      <div v-for="(share, index) in order.company.shares" :key="index">
        <div class="information-row"><span class="bold">{{ $t('payForm.numberOfShares') }}:</span> {{ share.shares }}</div>
        <div v-if="share.shareType === 'pair_valued'" class="information-row"><span class="bold">{{ $t('payForm.hasParValue') }}:</span> {{ share.parValue }}</div>
        <div v-if="share.shareType === 'class_valued'" class="information-row">
          <span class="bold">{{ $t('payForm.withoutParValue') }}</span> | <span class="bold">{{ $t('payForm.class') }}:</span> {{ share.shareClass }} - <span class="bold">{{ $t('payForm.series') }}:</span> {{ share.series }}
        </div>
        <hr class="divider" />
      </div>
    </div>
    <div class="section">
      <h1>Plan</h1>
      <div v-if="$i18n.locale === 'en'" class="information-row"><span class="bold">Plan:</span> {{ order.product.nameEn }}</div>
      <div v-if="$i18n.locale === 'es'" class="information-row"><span class="bold">Plan:</span> {{ order.product.nameEs }}</div>

      <div v-if="$i18n.locale === 'en'" class="information-row"><span class="bold">{{ $t('viewOrder.selectedState') }}:</span> {{ order.stateCommission.nameEn }}</div>
      <div v-if="$i18n.locale === 'es'" class="information-row"><span class="bold">{{ $t('viewOrder.selectedState') }}:</span> {{ order.stateCommission.nameEs }}</div>
    </div>
    <div class="section">
      <h1>{{ $t('viewOrder.purchaseDetails') }}</h1>
      <div class="information-row"><span class="bold">Total:</span> {{ order.formattedTotal }}</div>
    </div>
  </div>
</template>

<script>
import { getOrder } from '@/api/order'

export default {
  name: 'Order',
  data() {
    return {
      order: {},
      loading: true
    }
  },
  methods: {
    fetchData() {
      const email = this.$route.params.userEmail
      const token = this.$route.params.token

      getOrder(email, token).then(response => {
        this.order = response.data
        this.loading = false
      }).catch(error => {
        throw error
      })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#order {
  margin: 0 3rem;
  text-align: left;
}

.title {
  font-size: 2.5rem;
  text-align: center;
}

.subtitle {
  font-size: 1.75rem;
  text-align: left;
}

.information-row {
  margin: 0.25rem 0;
}

.bold {
  font-weight: bold;
}

.divider {
  background-color: $black;
  border: 0;
  height: 1px;
}

</style>
