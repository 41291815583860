<template>
  <div id="customer-opinions-card">
    <q class="quote">{{ opinion }}</q>
    <div class="source">
      <div>Nombre</div>
      <div>Compañía</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomerOpinionsCard',
  data() {
    return {
      opinion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquam placerat mi et volutpat. Praesent sit amet nunc et tellus ornare venenatis nec eu diam. Nullam nibh nisl.',
      nameCustomer: 'Nombre',
      companyCustomer: 'Compañía'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#customer-opinions-card {
  background-color: $yellow;
  display: inline-block;
  padding: 1.25rem 2rem;
  text-align: left;
}

.quote {
  font-size: 1.25rem;
  line-height: 2.5rem;
  quotes: '"' '"' "'" "'";

  &::before {
    font-size: 2.5rem;
  }

  &::after {
    font-size: 2.5rem;
  }
}

.source {
  text-align: right;
}
</style>
