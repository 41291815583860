<template>
  <transition id="error-modal" name="modal" v-if="errorModalVisible">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h1>{{ $t('modals.error.title') }}</h1>
          </div>
          <div class="modal-body">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
            <p>
              {{ $t('modals.error.subtitle') }}
            </p>
            <p>
              {{ $t('modals.error.description') }}
            </p>
          </div>
          <div class="modal-footer">
            <button class="btn" @click.prevent="closeErrorModal">
              {{ $t('payForm.back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ErrorModal',
  computed: {
    ...mapState('app', ['errorModalVisible'])
  },
  methods: {
    ...mapActions('app', ['closeErrorModal'])
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 9998;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin: 0 auto;
  padding: 20px 30px;
  transition: all 0.3s ease;
  width: 400px;
}

.modal-header h1 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.btn {
  background-color: $black;
  border: 1px solid $black;
  color: $white;
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0.75rem 0;
  padding: 0.75rem 1.5rem;

  &:focus {
    outline-width: 0;
  }
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transform: opacity 0.5s;
}

.icon {
  color: #f27474;
  height: 5rem;
}

@media (max-width: 30rem) {
  .modal-container {
    width: calc(75% - 60px);
  }
}
</style>
