<template>
  <div id="payment">
    <OrderPreview />
    <CompanyTypeForm :showBtn="false" />
    <div class="flex container">
      <PaymentForm>
        <Company @nextStep="nextStep" v-if="paymentStep === 1" />
        <Management @nextStep="nextStep" @previousStep="previousStep" v-if="paymentStep === 2" />
        <Capital @nextStep="nextStep" @previousStep="previousStep" v-if="paymentStep === 3" />
        <Review @nextStep="nextStep" @previousStep="previousStep" v-if="paymentStep === 4" />
        <Pay @previousStep="previousStep" v-if="paymentStep === 5" />
      </PaymentForm>
      <PaymentStepIndicator :payment-step="paymentStep" />
    </div>
    <p v-if="paymentStep > 2" class="disclaimer">
      {{ $t('payForm.sharesDisclaimer') }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderPreview from '@/components/OrderPreview'
import Company from '@/components/AddInfo/Company'
import Management from '@/components/AddInfo/Management'
import Capital from '@/components/AddInfo/Capital'
import Review from '@/components/AddInfo/Review'
import Pay from '@/components/AddInfo/Pay'
import CompanyTypeForm from '@/components/CompanyTypeForm'
import PaymentForm from '@/components/PaymentForm'
import PaymentStepIndicator from '@/components/PaymentStepIndicator'

export default {
  name: 'Payment',
  components: {
    OrderPreview,
    Company,
    Management,
    Capital,
    Review,
    Pay,
    PaymentForm,
    PaymentStepIndicator,
    CompanyTypeForm
  },
  data() {
    return {
      paymentStep: 1
    }
  },
  computed: {
    ...mapState('order', ['productId', 'stateCommissionId'])
  },
  watch: {
    paymentStep: function(val) {
      if (!this.productId && !this.stateCommissionId) {
        this.paymentStep = 1
      }
      else {
        this.paymentStep = val
      }
    }
  },
  methods: {
    nextStep() {
      this.paymentStep++
    },
    previousStep() {
      this.paymentStep--
    }
  }
}
</script>

<style>
#payment {
  margin: 3rem auto;
  width: 70%;
}

.container {
  margin: auto;
  position: relative;
  width: 85%;
}

.disclaimer {
  font-size: 0.75rem;
  text-align: left;
}

@media (max-width: 60rem) {
  #payment {
    margin: 3rem 1rem;
    width: auto;
  }
}

@media (max-width: 30rem) {
  .container {
    width: auto;
  }
}
</style>
