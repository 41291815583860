import { getProducts } from '@/api/products'

const getDefaultState = () => {
  return {
    all: []
  }
}

const state = getDefaultState()

const mutations = {
  UPDATE_PRODUCTS: (state, value) => {
    state.all = value
  }
}

const actions = {
  setProducts({ commit }) {
    getProducts().then(response => {
      commit('UPDATE_PRODUCTS', response.data)
    }).catch(error => {
      throw error
    })
  }
}

const getters = {
  products: state => {
    return state.all
  },
  selectedProduct: (state, getters, rootState) => {
    return state.all.find(product => product.id === rootState.order.productId)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
