<template>
  <div id="the-footer-contact-information">
    <h2 class="title">{{ $t('footer.findUs') }}</h2>
    <div class="location flex-sm align-center-sm">
      <img src="@/assets/images/flag_USA.png" class="flag-img" />
      <p class="text">
        1209 S. 10th St., Suite A #583,
        McAllen, TX 78501
      </p>
    </div>
    <div class="location flex-sm align-center-sm">
      <img src="@/assets/images/flag_MX.png" class="flag-img" />
      <p class="text">
        Ixtapa 501, Mitras Nte. Monterrey N.L.
        C.P. 64320
      </p>
    </div>
    <nav class="flex-m justify-center-m">
      <a href="https://twitter.com/WardenclyffeUS" target="_blank" class="link">
        <img src="@/assets/images/twitter-logo-black.png" class="icon"/>
      </a>
      <a href="https://open.spotify.com/show/07jhwqvcfnZGdVPJCeTtAN?si=E_kaNKKeRFWu5s71EnCiCg" target="_blank" class="link">
        <img src="@/assets/images/spotify-logo-black.png" class="icon"/>
      </a>
      <a href="https://www.instagram.com/wardenclyffefirm" target="_blank" class="link">
        <img src="@/assets/images/instagram-logo-black.png" class="icon"/>
      </a>
      <a href="https://www.facebook.com/wardenclyffeinc" target="_blank" class="link">
        <img src="@/assets/images/facebook-logo-black.png" class="icon"/>
      </a>
      <a href="https://www.linkedin.com/company/65438634" target="_blank" class="link">
        <img src="@/assets/images/linkedin-logo-black.png" class="icon"/>
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'TheFooterContactInformation'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#the-footer-contact-information {
  margin: 1rem;
  text-align: left;
  width: calc(100% / 3);
}

.title {
  text-align: center;
}

.location {
  margin: 1rem 0;
}

.flag-img {
  width: 75px;
}

.text {
  margin-top: 0.25rem;
  width: 17.5rem;
}

.link {
  margin: 0.5rem;

  &:nth-child(1) {
    margin-left: 0;
  }

  &:nth-last-child(1) {
    margin-right: 0;
  }
}

.icon {
  height: 1.25rem;
}

@media (max-width: 60rem) {
  #the-footer-contact-information {
    margin: 0;
  }

  .location {
    margin: 0 1.5rem;
  }

  .text {
    width: auto;
  }
}

@media (max-width: 30rem) {
  #the-footer-contact-information {
    margin: 1rem 0 0;
    width: 100%;
  }

  .location {
    margin: 1rem 2.5rem;
  }

  .text {
    margin: 0 0 0 1rem;
    width: auto;
  }

  .link {
    margin: 1rem;
  }
}
</style>
