<template>
  <div id="appoinment-link-banner" class="flex justify-space-evenly align-center column-sm">
    <section class="content-appoinment flex column align-start align-center-m">
      <h1>{{ title }}</h1>
      <h3>{{ subtitle }}</h3>
    </section>
    <button class="btn" @click.prevent="$router.push({name: 'Contact'})">{{ $t('home.contactBanner.button') }}</button>
  </div>
</template>

<script>
export default {
  name: 'AppointmentLinkBanner',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#appoinment-link-banner {
  background-color: $light-grey;
  margin: 2rem 0;
  width: 100%;
}

.content-appoinment {
  width: 60%;

  h1 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: -1rem;
    text-align: start;
  }
}

.btn {
  background-color: $red;
  border: 0;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  font-size: 1.25rem;
  margin: 12px 0;
  padding: 0.5rem 2.25rem;

  &:focus {
    outline-width: 0;
  }
}

@media (max-width: 30rem) {
  .content-appoinment {
    h1 {
      font-size: 2rem;
    }

    h3 {
      text-align: center;
    }
  }
}

</style>
