<template>
  <div id="after-payment" class="flex column align-center">
    <h1>{{ $t('home.afterPayment.title') }}</h1>
    <section>
      <ul>
        <li v-for="(step, index) in $t('home.afterPayment.steps')" :key="index">
          {{ step }}
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AfterPayment'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/global';
@import '@/assets/styles/constants';

#after-payment {
  margin: 1rem auto;

  h1 {
    font-size: 3rem;
  }

  section {
    text-align: start;
    width: 70%;
  }

  li {
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 60rem) {
  #after-payment {
    h1 {
      font-size: 2rem;
      margin: auto 1rem;
    }

    section {
      margin: 0 1.5rem;
      width: auto;
    }
  }
}
</style>
