<template>
  <div id="the-mobile-sidebar" class="inactive column align-stretch justify-space-between dspl-none">
    <div class="flex column">
      <img src="@/assets/images/logo.svg" class="logo align-self-center" />
      <router-link @click.native="closeSidebar" :to="routeUrl('/about-us')" class="link">
        {{ $t('header.aboutUs') }}
      </router-link>
      <router-link @click.native="closeSidebar" :to="routeUrl('/business-organization')" class="link">
        {{ $t('header.createYourCompany') }}
      </router-link>
      <router-link @click.native="closeSidebar" :to="routeUrl('/pricing')" class="link">
        {{ $t('header.plans') }}
      </router-link>
      <router-link @click.native="closeSidebar" :to="routeUrl('/contact')" class="link">
        {{ $t('header.contactUs') }}
      </router-link>
    </div>
    <div class="flex column">
      <span @click="changeLocale()" class="link">{{ languageLinkText }}</span>
      <nav class="flex justify-space-between">
        <a href="https://twitter.com/WardenclyffeUS" target="_blank">
          <img src="@/assets/images/twitter-logo-white.png" class="icon"/>
        </a>
        <a href="https://open.spotify.com/show/07jhwqvcfnZGdVPJCeTtAN?si=E_kaNKKeRFWu5s71EnCiCg" target="_blank">
          <img src="@/assets/images/spotify-logo-white.png" class="icon"/>
        </a>
        <a href="https://www.instagram.com/wardenclyffefirm" target="_blank">
          <img src="@/assets/images/instagram-logo-white.png" class="icon"/>
        </a>
        <a href="https://www.facebook.com/wardenclyffeinc" target="_blank">
          <img src="@/assets/images/facebook-logo-white.png" class="icon"/>
        </a>
        <a href="https://www.linkedin.com/company/65438634" target="_blank">
          <img src="@/assets/images/linkedin-logo-white.png" class="icon"/>
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMobileSidebar',
  computed: {
    languageLinkText() {
      return this.$i18n.locale === 'en' ? 'Español' : 'English'
    }
  },
  methods: {
    changeLocale() {
      this.closeSidebar()

      const currentRoute = this.$router.currentRoute.path
      let newRoute = ''

      if (this.$i18n.locale === 'en') {
        newRoute = currentRoute.replace(/^.{3}/g, '/es')
        this.$i18n.locale = 'es'
      }
      else {
        newRoute = currentRoute.replace(/^.{3}/g, '/en')
        this.$i18n.locale = 'en'
      }

      this.$router.push({ path: newRoute })
    },
    routeUrl(url) {
      return `/${this.$i18n.locale}${url}`
    },
    closeSidebar() {
      const sidebar = document.getElementById('the-mobile-sidebar')

      if (sidebar.classList.contains('active')) {
        sidebar.classList.add('animate-out')
      }
    }
  },
  mounted() {
    const sidebar = document.getElementById('the-mobile-sidebar')

    sidebar.addEventListener('animationend', function() {
      if (sidebar.classList.contains('inactive')) {
        sidebar.classList.add('active')

        sidebar.classList.remove('inactive')
        sidebar.classList.remove('animate-in')
        sidebar.classList.remove('animate-out')
      }
      else if (sidebar.classList.contains('active')) {
        sidebar.classList.add('inactive')

        sidebar.classList.remove('active')
        sidebar.classList.remove('animate-out')
        sidebar.classList.remove('animate-in')
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#the-mobile-sidebar {
  animation-fill-mode: forwards;
  background-color: $black;
  font-size: 0.75rem;
  height: calc(100vh - 3.5rem);
  padding: 1.75rem;
  position: fixed;
  width: calc(75% - 3.5rem);
  z-index: 5;

  &.active {
    left: 0;
  }

  &.inactive {
    left: calc(-1 * 75%);
  }

  &.animate-in {
    animation-duration: 0.8s;
    animation-name: slide-in;
  }

  &.animate-out {
    animation-duration: 0.8s;
    animation-name: slide-out;
  }
}

.logo {
  margin-bottom: 2.75rem;
  width: 10rem;
}

.link {
  color: $white;
  font-size: 1.5em;
  margin: 0 0 1.75rem;
  text-align: left;
  text-decoration: none;
}

.icon {
  height: 1.25rem;
}

@keyframes slide-in {
  to { left: 0; }
}

@keyframes slide-out {
  to { left: calc(-1 * 75%); }
}

@media (max-width: 60rem) {
  #the-mobile-sidebar {
    display: flex;
  }
}
</style>
