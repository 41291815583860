const getDefaultState = () => {
  return {
    loadingModalVisible: false,
    errorModalVisible: false,
    successModalVisible: false,
    successOrderRedirectUrl: ''
  }
}

const state = getDefaultState()

const mutations = {
  CHANGE_SUCCESS_MODAL_VISIBLE: (state, value) => {
    state.successModalVisible = value
  },
  CHANGE_ERROR_MODAL_VISIBLE: (state, value) => {
    state.errorModalVisible = value
  },
  CHANGE_LOADING_MODAL_VISIBLE: (state, value) => {
    state.loadingModalVisible = value
  },
  CHANGE_SUCCESS_ORDER_REDIRECT_URL: (state, value) => {
    state.successOrderRedirectUrl = value
  }
}

const actions = {
  openSuccessModal({ commit }) {
    commit('CHANGE_SUCCESS_MODAL_VISIBLE', true)
  },
  openErrorModal({ commit }) {
    commit('CHANGE_ERROR_MODAL_VISIBLE', true)
  },
  openLoadingModal({ commit }) {
    commit('CHANGE_LOADING_MODAL_VISIBLE', true)
  },
  closeSuccessModal({ commit }) {
    commit('CHANGE_SUCCESS_MODAL_VISIBLE', false)
  },
  closeErrorModal({ commit }) {
    commit('CHANGE_ERROR_MODAL_VISIBLE', false)
  },
  closeLoadingModal({ commit }) {
    commit('CHANGE_LOADING_MODAL_VISIBLE', false)
  },
  changeSuccessOrderRedirectUrl({ commit }, value) {
    commit('CHANGE_SUCCESS_ORDER_REDIRECT_URL', value)
  },
  initializeStore({ commit, dispatch }) {
    const orderProductId = localStorage.getItem('orderProductId')
    const orderStateCommissionId = localStorage.getItem('orderStateCommissionId')

    if (orderProductId) {
      dispatch('order/updateProductId', parseInt(orderProductId), { root: true })
    }

    if (orderStateCommissionId) {
      dispatch('order/updateStateCommissionId', parseInt(orderStateCommissionId), { root: true })
    }

    dispatch('products/setProducts', {}, { root: true })
    dispatch('stateCommissions/setStateCommissions', {}, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
