<template>
  <div id="payment-step-indicator" class="flex column justify-space-between dspl-none-m">
    <PaymentStep
      v-for="step in steps"
      :key="step.step"
      :is-active="paymentStep == step.step"
      :name="step.name"
      :step="step.step"
    />
  </div>
</template>

<script>
import PaymentStep from '@/components/PaymentStep'

export default {
  name: 'PaymentStepIndicator',
  components: {
    PaymentStep
  },
  props: {
    paymentStep: {
      type: Number
    }
  },
  data() {
    return {
      steps: [
        { step: 1, name: this.$t('payForm.company') },
        { step: 2, name: this.$t('payForm.management') },
        { step: 3, name: this.$t('payForm.capital') },
        { step: 4, name: this.$t('payForm.review') },
        { step: 5, name: this.$t('payForm.pay') }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#payment-step-indicator {
  height: 85%;
  left: 97%;
  position: absolute;
  top: 2rem;
}
</style>
