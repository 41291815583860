<template>
  <div id="products-list-item-best-seller-tag" class="flex column">
    <div class="content">
      <p class="label">
        BEST SELLER
      </p>
    </div>
    <div class="triangle" />
  </div>
</template>

<script>
export default {
  name: 'ProductsListItemBestSellerTag'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#products-list-item-best-seller-tag {
  color: #ffffff;
  position: absolute;
  right: 1rem;
  text-align: center;
  top: -0.5rem;
  width: 4rem;
}

.content {
  background-color: $red;
  border-radius: 5px 5px 0 0;
  padding-top: 2rem;
}

.label {
  line-height: 1rem;
}

.triangle {
  border-bottom: 20px solid transparent;
  border-left: 2rem solid $red;
  border-right: 2rem solid $red;
  bottom: 0;
}
</style>
