<template>
  <div id="products-list-item" :class="['flex', 'column', {'selected': product.id === productId}]">
    <ProductsListItemBestSellerTag v-if="bestSeller" />
    <h2>{{ product[$i18n.locale].name }}</h2>
    <p>USD<span class="price">{{ product.formattedAmount }}</span></p>
    <div class="description" v-html="product[$i18n.locale].description" />
    <button class="btn" @click.prevent="changeSelectedProduct(product.id)">{{ $t('productsList.button') }}</button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProductsListItemBestSellerTag from '@/components/ProductsListItemBestSellerTag'

export default {
  name: 'ProductsListItem',
  components: {
    ProductsListItemBestSellerTag
  },
  props: {
    product: {
      type: Object
    },
    bestSeller: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('order', ['productId'])
  },
  methods: {
    ...mapActions('order', [
      'updateProductId'
    ]),
    changeSelectedProduct(id) {
      this.updateProductId(id).then(() => {
        const companyInfoForm = document.getElementById('company-info')

        if (companyInfoForm) {
          companyInfoForm.scrollIntoView({
            behavior: 'smooth'
          })
        }
        else {
          this.$router.push({ name: 'Pay' })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/constants';

#products-list-item {
  background-color: $light-grey;
  height: 50rem;
  padding: 3rem 1rem 1rem;
  position: relative;
  text-align: left;
  width: 20%;

  &.selected {
    border: 4px solid $blue;
    height: calc(50rem - 8px);
    width: calc(20% - 8px);
  }

  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:first-child {
    border-radius: 30px 0 0 30px;
  }

  &:last-child {
    border-radius: 0 30px 30px 0;
  }
}

.price {
  font-size: 3rem;
  font-weight: bold;
}

.description /deep/ ul {
  padding-inline-start: 1rem;
}

.description /deep/ p {
  font-size: 1.25rem;
  font-weight: bold;
}

.btn {
  background-color: $blue;
  border: 0;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  margin: auto auto 1rem;
  padding: 0.5rem 2.75rem;

  &:focus {
    outline-width: 0;
  }
}

.login-buttons {
  margin-bottom: 9%;
  width: 100%;
}

@media (max-width: 90rem) {
  .price {
    font-size: 2.5rem;
  }
}

@media (max-width: 60rem) {
  #products-list-item {
    height: 52rem;

    &.selected {
      border: 4px solid $blue;
      height: calc(52rem - 8px);
    }
  }

  .price {
    font-size: 1.75rem;
  }
}

@media (max-width: 30rem) {
  #products-list-item {
    width: 100%;

    &.selected {
      border: 4px solid $blue;
      height: calc(52rem - 8px);
      width: calc(100% - 8px);
    }

    &:first-child {
      margin-left: 2rem;
    }

    &:last-child {
      margin-right: 2rem;
    }
  }

  .price {
    font-size: 2rem;
  }
}
</style>
